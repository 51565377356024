import reducer from './reducer';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { getFirestore, reduxFirestore } from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase';
import firebase from '../config/fbConfig';
import persistState  from 'redux-localstorage';

// ==============================|| REDUX - MAIN STORE ||============================== //
const middleware = [thunk.withExtraArgument({ getFirebase, getFirestore })];
const store = createStore(
    reducer,
    compose(
        applyMiddleware(...middleware),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        reduxFirestore(firebase),
        // persistState('bookings')
    )
);

const persister = 'bookings';

export { store, persister };
