import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { firestoreConnect, useFirestoreConnect } from 'react-redux-firebase';
import { DropzoneArea } from 'react-mui-dropzone';
import { useParams } from 'react-router-dom';
import { editListing, editListingPhotos } from '../../../store/actions';

// material-ui
import {
    FormHelperText,
    InputAdornment,
    Box,
    Typography,
    Container,
    Chip,
    Step,
    Stepper,
    StepContent,
    StepButton,
    Button,
    Paper,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Grid,
    OutlinedInput,
    Card,
    CardMedia,
    IconButton
} from '@mui/material';
import { useNavigate } from 'react-router';
import { Delete } from '@mui/icons-material';

function convertToSlug(Text) {
    return Text.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

//= =============================|| EDIT LISTING PAGE ||==============================//
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const EditListing = (props) => {
    const { listingFilters } = props;
    const navigate = useNavigate();
    const params = useParams();
    const listingId = params.listingId;
    useFirestoreConnect([{ collection: 'listing_info', doc: listingId, storeAs: 'listing' }]);
    const listing = useSelector((state) => state.firestore.data.listing);
    const [activeStep, setActiveStep] = useState(0);
    const [images, setImages] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setMessage] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [listingInfo, setListingInfo] = useState(null);
    const [imgPath, setImgPath] = useState([])

    const handleChangeImages = (images) => {
        setImages(images);
    };

    const handleChangeDocuments = (documents) => {
        setDocuments(documents);
    };

    const handleChipChange = (event) => {
        const tempListingInfo = { ...listingInfo };
        const {
            target: { value }
        } = event;
        tempListingInfo.highlights = typeof value === 'string' ? value.split(',') : value;

        setListingInfo(tempListingInfo);
    };

    const handleChange = (e) => {
        const tempListingInfo = JSON.parse(JSON.stringify(listingInfo));
        const source = e.target.id || e.target.name || null;
        console.log(source);
        if (source) {
            const parts = source.split('.');
            if (parts[0] === 'location_info') {
                if (parts.length === 3) {
                    if (!tempListingInfo[parts[1]]) {
                        tempListingInfo[parts[1]] = {};
                    }
                    tempListingInfo[parts[1]][parts[2]] = e.target.value;
                } else {
                    tempListingInfo[parts[1]] = e.target.value;
                }
            } else if (parts.length === 1) {
                tempListingInfo[parts[0]] = e.target.value;
            } else if (parts.length === 2) {
                if (!tempListingInfo[parts[0]]) {
                    tempListingInfo[parts[0]] = {};
                }
                tempListingInfo[parts[0]][parts[1]] = e.target.value;
            } else if (parts.length === 3) {
                if (!tempListingInfo[parts[0]]) {
                    tempListingInfo[parts[0]] = {};
                }
                if (!tempListingInfo[parts[0]][parts[1]]) {
                    tempListingInfo[parts[0]][parts[1]] = {};
                }
                tempListingInfo[parts[0]][parts[1]][parts[2]] = e.target.value;
            }

            setListingInfo(tempListingInfo);
        }
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.editListing(listingId, listingInfo, images, documents);
        const route = convertToSlug(
            `${listingInfo.type_place} ${listingInfo.name} ${listingInfo.address.city} ${listingInfo.address.state}`
        );
        navigate(`/listing/${route}/${listingId}`);
    };

    if (listingInfo == null && listing) {
        console.log('writting');
        setListingInfo({ ...listing });
        setImages(listing.images);
        setDocuments(listing.documents);
    }

    const handleDeleteImg = (element) => {

        const newData = listing.images.filter((img) => {
            if(img !== element){
                return element;
            }
        });
        
        setImgPath(prevData => ([...newData]));

        props.editListingPhotos(listingId, newData);

    }
      
    if (listingInfo && listingFilters) {
        return (
            <Container style={{ padding: '16px' }}>
                <Box style={{ padding: '16px' }}>
                    <Typography variant="h2" style={{ padding: '16px' }}>
                        Edit your listing
                    </Typography>

                    <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                        {listingFilters.map((step, index) => (
                            <Step key={step.label} active>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    {step.label}
                                </StepButton>
                                <StepContent>
                                    <Typography>{step.description}</Typography>
                                    <>
                                    <Grid container spacing={2} padding={2}>
                                        {step.upload && step.key === 'images' ? (
                                            <>
                                                <Grid item xs={12}>
                                                    <DropzoneArea
                                                        acceptedFiles={['image/*']}
                                                        dropzoneText="Drag and drop an image here or click"
                                                        onChange={handleChangeImages}
                                                        filesLimit={15}
                                                        // initialFiles={images || []}
                                                    />
                                                </Grid>
                                                <Grid container spacing={2} padding={2}>
                                                {
                                                    listing.images.map((row, idx) => 
                                                    ( 
                                                        <Grid item xl={2}>
                                                            <Card >
                                                            <div style={{ position: "relative" }} >
                                                                <CardMedia
                                                                    style={{ height: 100, paddingTop: "0%", cursor: 'pointer' }}
                                                                    component="img"

                                                                    image={row}
                                                                    alt="Pancakes" />
                                                                <div style={{ position: "absolute", color: "white", fontWeight: "bold", top: 0, left: "90%", transform: "translateX(-50%)", }}>
                                                                    <Button 
                                                                            variant='text' 
                                                                            size='small' 
                                                                            color='error'
                                                                            onClick={()=>handleDeleteImg(row)}>
                                                                            <IconButton color="error"  component="span">
                                                                                <Delete />
                                                                            </IconButton>
                                                                            
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                        </Grid>
                                                    ))
                                                }
                                                
                                                </Grid>
                                            </>
                                            
                                        ) : null}
                                        {step.upload && step.key === 'terms_conditions' ? (
                                            <>
                                            <Grid item xs={12}>
                                                <DropzoneArea
                                                    acceptedFiles={['application/pdf']}
                                                    showFileNames
                                                    dropzoneText="Drag and drop a document here or click"
                                                    onChange={handleChangeDocuments}
                                                    filesLimit={5}
                                                    initialFiles={documents}
                                                />
                                            </Grid>
                                            <Grid container spacing={2} padding={2}>
                                            {
                                                listing.documents?.map((row, idx) => 
                                                ( 
                                                    <Grid item xl={2}>
                                                        <Card >
                                                        <div style={{ position: "relative" }} >
                                                            <CardMedia
                                                                style={{ height: 100, paddingTop: "0%", cursor: 'pointer' }}
                                                                component="img"

                                                                image={row}
                                                                alt="Pancakes" />
                                                            <div style={{ position: "absolute", color: "white", fontWeight: "bold", top: 0, left: "90%", transform: "translateX(-50%)", }}>
                                                                <Button 
                                                                        variant='text' 
                                                                        size='small' 
                                                                        color='error'>
                                                                        <IconButton color="error"  component="span">
                                                                            <Delete />
                                                                        </IconButton>
                                                                        
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                    </Grid>
                                                ))
                                            }
                                            
                                            </Grid>
                                        </>
                                        ) : null}
                                        {step.fields &&
                                            step.fields.map((field, index) => (
                                                <Grid item xs={field.size ? field.size : 12} key={index}>
                                                    <CreateField
                                                        step={step.id}
                                                        field={field}
                                                        handleChange={handleChange}
                                                        handleChipChange={handleChipChange}
                                                        listingInfo={listingInfo}
                                                    />
                                                </Grid>
                                            ))}
                                    </Grid>
                                    </>

                                    {index === listingFilters.length - 1 ? (
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    onClick={index === listingFilters.length - 1 ? handleSubmit : handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {index === listingFilters.length - 1 ? 'Finish' : 'Continue'}
                                                </Button>

                                                {/* <Button disabled={index === 0} onClick={this.handleBack} sx={{ mt: 1, mr: 1 }}>
                                                        Back
                                                    </Button> */}

                                                {error ? (
                                                    <Typography variant="button" sx={{ mt: 1, mr: 1 }} color="error">
                                                        {errorMessage}
                                                    </Typography>
                                                ) : null}
                                            </div>
                                        </Box>
                                    ) : null}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === listingFilters.length && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                Reset
                            </Button>
                        </Paper>
                    )}
                </Box>
            </Container>
        );
    }
    return null;
};

function CreateField({ step, field, handleChange, handleChipChange, listingInfo }) {
    if (field.chip) {
        const value = step === 'location_info' ? listingInfo[field.key] : listingInfo[step] || [];
        return (
            <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="demo-multiple-chip-label">{field.label}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id={`${step}.${field.key}`}
                    name={`${step}.${field.key}`}
                    multiple
                    value={value}
                    onChange={handleChipChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={field.options.find((x) => x.key === value).label} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {typeof field.options !== 'boolean' &&
                        field.options.map((option, index) => (
                            <MenuItem value={option.key} key={index}>
                                {option.label}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        );
    }
    if (field.options) {
        let value =
            listingInfo[step] && listingInfo[step][field.key] ? field.options.find((x) => x.key === listingInfo[step][field.key]) : '';
        if (step === 'location_info') {
            value = listingInfo[field.key] ? field.options.find((x) => x.key === listingInfo[field.key]) : '';
        }
        value = value && value.key ? value.key : value;
        return (
            <FormControl fullWidth key={`select-${field.key}`}>
                <InputLabel id={`select-label-${field.key}`}>{field.label}</InputLabel>
                <Select
                    labelId={`select-label-${field.key}`}
                    id={`${step}.${field.key}`}
                    name={`${step}.${field.key}`}
                    required={field.required ? field.required : false}
                    value={value}
                    label={field.label}
                    onChange={handleChange}
                >
                    {typeof field.options !== 'boolean' &&
                        field.options.map((option, index) => (
                            <MenuItem value={option.key} key={index}>
                                {option.label}
                            </MenuItem>
                        ))}
                </Select>
                {field.help_text ? <FormHelperText id={`${field.key}-helper-text`}>{field.help_text}</FormHelperText> : null}
            </FormControl>
        );
    }
    if (field.fields) {
        let value = listingInfo[step] && listingInfo[step][field.key] ? listingInfo[step][field.key] : '';
        if (step === 'location_info') {
            value = listingInfo[field.key] ? listingInfo[field.key] : '';
        }
        return (
            <Grid container spacing={2}>
                {field.fields.map((subfield, index) => (
                    <Grid item xs={subfield.size ? subfield.size : 12 / field.fields.length} key={index}>
                        <TextField
                            id={`${step}.${field.key}.${subfield.key}`}
                            name={`${step}.${field.key}.${subfield.key}`}
                            required={field.required ? field.required : false}
                            value={value && value[subfield.key] ? value[subfield.key] : ''}
                            label={subfield.label}
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }
    if (field.numeric) {
        let value = listingInfo[step] && listingInfo[step][field.key] ? listingInfo[step][field.key] : '';
        if (step === 'location_info') {
            value = listingInfo[field.key] ? listingInfo[field.key] : '';
        }
        return (
            <TextField
                id={`${step}.${field.key}`}
                name={`${step}.${field.key}`}
                required={field.required ? field.required : false}
                value={value}
                label={field.label}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                type="number"
                InputLabelProps={{
                    shrink: true
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
        );
    }
    if (field.currency) {
        let value = listingInfo[step] && listingInfo[step][field.key] ? listingInfo[step][field.key] : '';
        if (step === 'location_info') {
            value = listingInfo[field.key] ? listingInfo[field.key] : '';
        }
        return (
            <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor={`${step}.${field.key}`}>{field.label}</InputLabel>
                <OutlinedInput
                    fullWidth
                    id={`${step}.${field.key}`}
                    name={`${step}.${field.key}`}
                    required={field.required ? field.required : false}
                    value={value}
                    onChange={handleChange}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label={field.label}
                />
                {field.help_text ? <FormHelperText id={`${field.key}-helper-text`}>{field.help_text}</FormHelperText> : null}
            </FormControl>
        );
    }
    let value = listingInfo[step] && listingInfo[step][field.key] ? listingInfo[step][field.key] : '';
    if (step === 'location_info') {
        value = listingInfo[field.key] ? listingInfo[field.key] : '';
    }
    return (
        <FormControl fullWidth>
            <TextField
                id={`${step}.${field.key}`}
                name={`${step}.${field.key}`}
                required={field.required ? field.required : false}
                value={value}
                label={field.label}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                multiline={!!field.multiline}
                rows={field.multiline}
            />
            {field.help_text ? <FormHelperText id={`${field.key}-helper-text`}>{field.help_text}</FormHelperText> : null}
        </FormControl>
    );
}

const mapDispatchToProps = (dispatch) => ({
    editListing: (listingId, listingInfo, images, documents) => dispatch(editListing(listingId, listingInfo, images, documents)),
    editListingPhotos:(listingId, images) => dispatch(editListingPhotos(listingId, images)),
});

const mapStateToProps = (state) => {
    const listingFilters = state.firestore.data.listing_filters ? state.firestore.ordered.listing_filters : null;

    return {
        listingFilters,
        auth: state.firebase.auth
    };
};

export default compose(
    connect(null, mapDispatchToProps),
    connect(mapStateToProps),
    firestoreConnect((props) => [
        {
            collection: 'listing_filters',
            orderBy: ['order']
        }
    ])
)(EditListing);
