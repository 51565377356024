import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { DropzoneArea } from 'react-mui-dropzone';
import { saveListing } from '../../../store/actions';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

// material-ui
import {
    FormHelperText,
    InputAdornment,
    Box,
    Typography,
    Container,
    Chip,
    Step,
    Stepper,
    StepLabel,
    StepContent,
    StepButton,
    Button,
    Paper,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Grid,
    OutlinedInput
} from '@mui/material';
import { Navigate } from 'react-router-dom';
import { Nightlife } from '@mui/icons-material';

// project imports

//= =============================|| CREATE LISTING PAGE ||==============================//
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

function CreateField({ step, field, handleChange, handleChipChange, listingInfo, errorFields }) {
    if (field.chip) {
        return (
            <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="demo-multiple-chip-label">{field.label}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id={`${step}.${field.key}`}
                    name={`${step}.${field.key}`}
                    multiple
                    value={listingInfo[step] || []}
                    onChange={handleChipChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={field.options.find((x) => x.key === value).label} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {typeof field.options !== 'boolean' &&
                        field.options.map((option, index) => (
                            <MenuItem value={option.key} key={index}>
                                {option.label}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        );
    }
    if (field.options) {
        return (
            <FormControl
                fullWidth
                key={`select-${field.key}`}
                error={errorFields && errorFields.includes(`${step}.${field.key}`)}
                required={field.required ? field.required : false}
            >
                <InputLabel id={`select-label-${field.key}`}>{field.label}</InputLabel>
                <Select
                    labelId={`select-label-${field.key}`}
                    id={`${step}.${field.key}`}
                    name={`${step}.${field.key}`}
                    value={
                        listingInfo[step] && listingInfo[step][field.key]
                            ? field.options.find((x) => x.key === listingInfo[step][field.key]).key
                            : ''
                    }
                    label={field.label}
                    onChange={handleChange}
                >
                    {typeof field.options !== 'boolean' &&
                        field.options.map((option, index) => (
                            <MenuItem value={option.key} key={index}>
                                {option.label}
                            </MenuItem>
                        ))}
                </Select>
                {field.help_text ? <FormHelperText id={`${field.key}-helper-text`}>{field.help_text}</FormHelperText> : null}
            </FormControl>
        );
    }
    if (field.fields) {
        return (
            <Grid container spacing={2}>
                {field.fields.map((subfield, index) => (
                    <Grid item xs={subfield.size ? subfield.size : 12 / field.fields.length} key={index}>
                        <TextField
                            error={errorFields && errorFields.includes(`${step}.${field.key}.${subfield.key}`)}
                            id={`${step}.${field.key}.${subfield.key}`}
                            name={`${step}.${field.key}.${subfield.key}`}
                            required={subfield.required ? subfield.required : false}
                            value={
                                listingInfo[step] && listingInfo[step][field.key] && listingInfo[step][field.key][subfield.key]
                                    ? listingInfo[step][field.key][subfield.key]
                                    : ''
                            }
                            label={subfield.label}
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }
    if (field.numeric) {
        return (
            <TextField
                id={`${step}.${field.key}`}
                name={`${step}.${field.key}`}
                error={errorFields && errorFields.includes(`${step}.${field.key}`)}
                required={field.required ? field.required : false}
                value={listingInfo[step] && listingInfo[step][field.key] ? listingInfo[step][field.key] : ''}
                label={field.label}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                type="number"
                InputLabelProps={{
                    shrink: true
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
        );
    }
    if (field.currency) {
        return (
            <FormControl
                fullWidth
                sx={{ m: 1 }}
                error={errorFields && errorFields.includes(`${step}.${field.key}`)}
                required={field.required ? field.required : false}
            >
                <InputLabel htmlFor={`${step}.${field.key}`}>{field.label}</InputLabel>
                <OutlinedInput
                    fullWidth
                    id={`${step}.${field.key}`}
                    error={errorFields && errorFields.includes(`${step}.${field.key}`)}
                    name={`${step}.${field.key}`}
                    value={listingInfo[step] && listingInfo[step][field.key] ? listingInfo[step][field.key] : ''}
                    onChange={handleChange}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label={field.label}
                />
                {field.help_text ? <FormHelperText id={`${field.key}-helper-text`}>{field.help_text}</FormHelperText> : null}
            </FormControl>
        );
    }
    return (
        <FormControl fullWidth>
            <TextField
                id={`${step}.${field.key}`}
                error={errorFields && errorFields.includes(`${step}.${field.key}`)}
                name={`${step}.${field.key}`}
                required={field.required ? field.required : false}
                value={listingInfo[step] && listingInfo[step][field.key] ? listingInfo[step][field.key] : ''}
                label={field.label}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                multiline={!!field.multiline}
                rows={field.multiline}
            />
            {field.help_text ? <FormHelperText id={`${field.key}-helper-text`}>{field.help_text}</FormHelperText> : null}
        </FormControl>
    );
}

class CreateListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 0,
            listingInfo: {},
            images: [],
            documents: [],
            errorFields: [],
            error: false,
            errorMessage: null,
            submited: false
        };
    }

    handleChangeImages = (images) => {
        this.setState({ images });
    };

    handleChangeDocuments = (documents) => {
        this.setState({ documents });
    };

    handleChipChange = (event) => {
        const { listingInfo } = this.state;
        const {
            target: { value }
        } = event;
        listingInfo.highlights = typeof value === 'string' ? value.split(',') : value;

        this.setState(listingInfo);
    };

    handleChange = (e) => {
        const { listingInfo } = this.state;
        const source = e.target.id || e.target.name || null;
        if (source) {
            const parts = source.split('.');

            if (parts.length === 1) {
                listingInfo[parts[0]] = e.target.value;
            } else if (parts.length === 2) {
                if (!listingInfo[parts[0]]) {
                    listingInfo[parts[0]] = {};
                }
                listingInfo[parts[0]][parts[1]] = e.target.value;
            } else if (parts.length === 3) {
                if (!listingInfo[parts[0]]) {
                    listingInfo[parts[0]] = {};
                }
                if (!listingInfo[parts[0]][parts[1]]) {
                    listingInfo[parts[0]][parts[1]] = {};
                }
                listingInfo[parts[0]][parts[1]][parts[2]] = e.target.value;
            }

            this.setState({
                listingInfo
            });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { listingInfo, images, documents } = this.state;
        const errorFields = [];
        let error = false;
        let errorMessage = null;

        if (!(listingInfo && listingInfo.location_info && listingInfo.location_info.name)) {
            error = true;
            errorMessage = 'Complete all required fields';
            errorFields.push('location_info.name');
        }
        if (!(listingInfo && listingInfo.location_info && listingInfo.location_info.type_place)) {
            error = true;
            errorMessage = 'Complete all required fields';
            errorFields.push('location_info.type_place');
        }
        if (!(listingInfo && listingInfo.location_info && listingInfo.location_info.type_stay)) {
            error = true;
            errorMessage = 'Complete all required fields';
            errorFields.push('location_info.type_stay');
        }
        if (
            !(
                listingInfo &&
                listingInfo.location_info &&
                listingInfo.location_info.address.city &&
                listingInfo.location_info.address.city !== ''
            )
        ) {
            error = true;
            errorMessage = 'Complete all required fields';
            errorFields.push('location_info.address.city');
        }
        if (
            !(
                listingInfo &&
                listingInfo.location_info &&
                listingInfo.location_info.address.neigborhood &&
                listingInfo.location_info.address.neigborhood !== ''
            )
        ) {
            error = true;
            errorMessage = 'Complete all required fields';
            errorFields.push('location_info.address.neigborhood');
        }
        if (
            !(
                listingInfo &&
                listingInfo.location_info &&
                listingInfo.location_info.address.state &&
                listingInfo.location_info.address.state !== ''
            )
        ) {
            error = true;
            errorMessage = 'Complete all required fields';
            errorFields.push('location_info.address.state');
        }
        if (
            !(
                listingInfo &&
                listingInfo.location_info &&
                listingInfo.location_info.address.country &&
                listingInfo.location_info.address.country !== ''
            )
        ) {
            error = true;
            errorMessage = 'Complete all required fields';
            errorFields.push('location_info.address.country');
        }
        if (!(listingInfo && listingInfo.pricing && listingInfo.pricing.pricing_general && listingInfo.pricing.pricing_general !== '')) {
            error = true;
            errorMessage = 'Complete all required fields';
            errorFields.push('pricing.pricing_general');
        }
        this.setState({ errorFields, error, errorMessage });
        if (!error) {
            const { saveListing } = this.props;
            saveListing(listingInfo, images, documents);
            this.setState({ submited: true });
        }
    };

    handleNext = () => {
        const { activeStep } = this.state;
        this.setState({ activeStep: activeStep + 1 });
    };

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({ activeStep: activeStep - 1 });
    };

    handleStep = (step) => () => {
        this.setState({ activeStep: step });
    };

    handleReset = () => {
        this.setState({ activeStep: 0 });
    };

    render() {
        const { activeStep, listingInfo, images, documents, error, errorFields, submited, errorMessage } = this.state;
        const { listingFilters, auth } = this.props;
        if (!auth.uid) return <Navigate replace to="/login" />;
        if (submited) return <Navigate to="/user/listings" />;
        if (listingFilters) {
            return (
                <Container style={{ padding: '16px' }}>
                    <Box style={{ padding: '16px' }}>
                        <Typography variant="h2" style={{ padding: '16px' }}>
                            Create a listing
                        </Typography>
                        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                            {listingFilters.map((step, index) => (
                                <Step key={step.label} active>
                                    <StepButton color="inherit" onClick={this.handleStep(index)}>
                                        {step.label}
                                    </StepButton>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                        <Grid container spacing={2} padding={2}>
                                            {step.upload && step.key === 'images' ? (
                                                <Grid item xs={12}>
                                                    <DropzoneArea
                                                        acceptedFiles={['image/*']}
                                                        dropzoneText="Drag and drop an image here or click"
                                                        onChange={this.handleChangeImages}
                                                        filesLimit={15}
                                                        initialFiles={images}
                                                    />
                                                </Grid>
                                            ) : null}
                                            {step.upload && step.key === 'terms_conditions' ? (
                                                <Grid item xs={12}>
                                                    <DropzoneArea
                                                        acceptedFiles={['application/pdf']}
                                                        showFileNames
                                                        dropzoneText="Drag and drop a document here or click"
                                                        onChange={this.handleChangeDocuments}
                                                        filesLimit={5}
                                                        initialFiles={documents}
                                                    />
                                                </Grid>
                                            ) : null}
                                            {step.fields &&
                                                step.fields.map((field, index) => (
                                                    <Grid item xs={field.size ? field.size : 12} key={index}>
                                                        <CreateField
                                                            step={step.id}
                                                            field={field}
                                                            handleChange={this.handleChange}
                                                            handleChipChange={this.handleChipChange}
                                                            listingInfo={listingInfo}
                                                            errorFields={errorFields}
                                                        />
                                                    </Grid>
                                                ))}
                                        </Grid>
                                        {index === listingFilters.length - 1 ? (
                                            <Box sx={{ mb: 2 }}>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        onClick={index === listingFilters.length - 1 ? this.handleSubmit : this.handleNext}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {index === listingFilters.length - 1 ? 'Finish' : 'Continue'}
                                                    </Button>

                                                    {/* <Button disabled={index === 0} onClick={this.handleBack} sx={{ mt: 1, mr: 1 }}>
                                                        Back
                                                    </Button> */}

                                                    {error ? (
                                                        <Typography variant="button" sx={{ mt: 1, mr: 1 }} color="error">
                                                            {errorMessage}
                                                        </Typography>
                                                    ) : null}
                                                </div>
                                            </Box>
                                        ) : null}
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === listingFilters.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>All steps completed - you&apos;re finished</Typography>
                                <Button onClick={this.handleReset} sx={{ mt: 1, mr: 1 }}>
                                    Reset
                                </Button>
                            </Paper>
                        )}
                    </Box>
                </Container>
            );
        }
        return null;
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveListing: (listingInfo, images, documents) => dispatch(saveListing(listingInfo, images, documents))
});

const mapStateToProps = (state) => {

    const listingFilters = state.firestore.data.listing_filters ? state.firestore.ordered.listing_filters : null;
    return {
        listingFilters,
        auth: state.firebase.auth
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection: 'listing_filters',
            orderBy: ['order']
        }
    ])
)(CreateListing);
