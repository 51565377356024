import rent from './rent';
import sale from './sale';
import hospital from './hospital';
import recovery from './recovery';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
    items: [hospital, recovery]
};

export default menuItems;
