// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ color }) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="50"
        viewBox="0 0 1672.000000 380.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,380.000000) scale(0.100000,-0.100000)" fill={color || '#FFFFFF'} stroke="none">
            <path
                d="M1212 3570 c-29 -10 -76 -36 -105 -56 -137 -98 -669 -519 -702 -555
-49 -55 -82 -128 -95 -216 -8 -48 -10 -223 -8 -520 4 -439 4 -449 26 -505 29
-73 95 -141 170 -176 l57 -27 790 0 790 0 55 26 c66 32 123 86 158 154 l27 50
3 505 c3 481 2 507 -17 570 -34 108 -70 145 -401 403 -387 303 -416 323 -486
347 -75 26 -190 26 -262 0z m252 -190 c17 -11 179 -135 361 -277 291 -228 332
-264 355 -308 l25 -49 0 -477 c0 -467 0 -478 -21 -505 -11 -15 -33 -37 -48
-48 -27 -21 -35 -21 -791 -21 -756 0 -764 0 -791 21 -15 11 -37 33 -48 48 -20
27 -21 41 -24 487 -3 479 -1 508 41 570 21 32 651 532 710 565 66 36 169 33
231 -6z"
            />
            <path
                d="M834 2280 c-46 -18 -68 -85 -44 -131 12 -21 107 -72 190 -101 198
-69 457 -75 670 -16 101 27 216 83 237 114 22 34 19 81 -7 109 -37 40 -64 39
-148 -1 -116 -54 -207 -75 -357 -81 -163 -6 -282 16 -413 76 -95 44 -96 44
-128 31z"
            />
            <path
                d="M11453 3166 l-28 -24 0 -792 0 -792 28 -24 c32 -28 66 -30 107 -9 17
9 31 17 32 18 1 1 5 169 8 372 l5 370 352 3 352 2 3 -366 3 -366 28 -24 c39
-33 81 -31 118 5 l29 29 0 787 0 786 -25 24 c-34 35 -93 34 -126 -1 l-24 -26
-5 -346 -5 -347 -350 0 -350 0 -5 353 -5 354 -24 19 c-33 27 -84 24 -118 -5z"
            />
            <path
                d="M2916 3139 l-26 -20 0 -780 0 -780 25 -24 c32 -33 83 -33 119 0 l26
24 0 315 c0 173 3 321 6 330 5 14 31 16 185 16 174 0 179 -1 192 -22 18 -29
81 -137 242 -413 147 -253 165 -275 213 -275 42 0 92 46 92 85 0 38 3 32 -259
463 -61 101 -111 189 -111 195 0 7 23 26 51 42 64 38 161 141 194 205 54 108
52 265 -6 387 -59 124 -208 236 -349 263 -29 5 -169 10 -310 10 -245 0 -259
-1 -284 -21z m580 -146 c146 -49 226 -156 226 -303 0 -129 -60 -223 -180 -282
l-66 -33 -200 -3 c-156 -3 -201 0 -207 10 -10 15 -12 588 -3 612 9 24 359 23
430 -1z"
            />
            <path
                d="M4507 2690 c-140 -36 -252 -133 -303 -265 -17 -45 -19 -77 -19 -320
0 -259 1 -272 23 -328 62 -153 205 -252 382 -264 203 -14 450 132 450 267 0
32 -44 70 -81 70 -26 0 -43 -12 -98 -70 -82 -85 -139 -113 -231 -114 -82 0
-132 17 -182 62 -64 58 -82 102 -83 207 l0 90 328 5 328 5 19 25 c23 31 30 65
30 149 0 224 -86 375 -258 453 -57 26 -81 31 -167 34 -55 2 -117 -1 -138 -6z
m204 -155 c60 -21 104 -58 136 -113 25 -42 28 -56 28 -142 l0 -95 -255 0 -255
0 -3 55 c-9 143 55 253 172 297 44 16 126 16 177 -2z"
            />
            <path
                d="M5633 2690 c-115 -23 -241 -120 -288 -222 -41 -89 -47 -142 -43 -396
4 -268 10 -295 87 -396 182 -242 618 -210 750 56 38 75 40 120 6 153 -49 50
-100 26 -158 -76 -33 -58 -93 -113 -143 -130 -52 -18 -160 -16 -205 3 -53 23
-105 73 -131 126 -22 45 -23 56 -23 297 0 235 1 253 21 295 43 88 116 138 216
147 111 11 201 -35 264 -132 19 -30 46 -62 59 -71 48 -31 115 9 115 69 -1 102
-121 222 -267 267 -69 21 -180 25 -260 10z"
            />
            <path
                d="M6683 2690 c-106 -22 -223 -108 -277 -201 -43 -76 -56 -161 -56 -384
0 -223 12 -306 56 -380 99 -170 314 -252 515 -197 172 48 287 176 311 346 5
39 8 168 6 286 -4 194 -7 220 -27 270 -46 112 -130 196 -241 240 -62 25 -216
35 -287 20z m210 -160 c64 -24 107 -64 139 -128 22 -45 23 -57 23 -282 0 -264
-7 -305 -61 -369 -50 -58 -112 -84 -199 -84 -88 0 -149 26 -200 86 -55 64 -60
94 -60 357 0 262 4 287 63 352 76 84 184 109 295 68z"
            />
            <path
                d="M8784 2690 c-65 -13 -157 -64 -208 -116 -98 -98 -126 -201 -126 -467
0 -260 22 -354 106 -447 143 -158 369 -193 575 -90 98 50 172 128 183 192 4
28 -17 73 -39 82 -44 16 -69 6 -128 -55 -33 -33 -77 -72 -99 -85 -116 -73
-293 -42 -365 65 -32 47 -43 94 -43 184 l0 77 319 0 320 0 28 24 c27 23 28 28
31 137 5 139 -9 216 -54 296 -55 99 -159 176 -275 202 -51 12 -166 12 -225 1z
m221 -164 c60 -28 108 -76 131 -131 23 -55 32 -194 13 -206 -8 -5 -126 -9
-263 -9 l-248 0 4 98 c3 80 8 105 28 141 66 117 214 164 335 107z"
            />
            <path
                d="M13103 2685 c-154 -43 -269 -160 -302 -306 -13 -57 -14 -491 -1 -547
53 -227 304 -369 545 -307 33 9 79 25 102 36 94 45 182 151 209 251 17 66 20
490 3 570 -26 121 -103 215 -226 274 -68 33 -84 37 -176 40 -70 3 -118 -1
-154 -11z m218 -150 c59 -20 114 -66 142 -118 21 -39 22 -57 25 -277 4 -261
-3 -316 -48 -378 -61 -85 -204 -120 -312 -79 -62 24 -129 98 -145 160 -9 31
-13 124 -13 270 0 259 7 295 75 362 69 70 179 93 276 60z"
            />
            <path
                d="M14265 2672 c-33 -16 -76 -44 -95 -61 l-35 -32 -10 24 c-29 71 -102
91 -150 42 l-25 -24 0 -519 c0 -502 1 -520 20 -544 35 -45 116 -41 143 6 9 15
13 134 17 438 l5 416 30 43 c37 52 91 81 161 87 95 8 172 -37 211 -123 16 -36
18 -80 21 -446 l3 -407 25 -23 c32 -30 88 -30 118 0 21 21 21 30 26 434 5 397
6 413 27 452 59 110 178 146 294 88 45 -23 59 -37 82 -82 l27 -55 0 -393 c0
-432 1 -441 58 -461 40 -14 79 -4 102 26 19 24 20 41 20 421 0 444 -3 472 -66
566 -37 57 -73 88 -144 126 -38 20 -59 24 -141 24 -91 0 -99 -2 -171 -38 -42
-21 -90 -54 -108 -72 l-33 -34 -52 47 c-75 69 -134 95 -225 99 -67 4 -81 1
-135 -25z"
            />
            <path
                d="M15933 2690 c-109 -23 -235 -118 -281 -212 -44 -90 -54 -173 -49
-408 3 -169 8 -227 22 -270 52 -162 204 -274 389 -287 208 -14 446 127 446
266 0 40 -34 71 -77 71 -28 0 -43 -11 -98 -68 -86 -91 -137 -116 -235 -115
-118 0 -193 44 -236 138 -19 40 -24 67 -24 138 l0 87 319 0 320 0 28 24 28 24
-1 149 c0 129 -3 155 -22 202 -48 115 -130 197 -242 241 -62 25 -216 35 -287
20z m202 -157 c62 -21 139 -95 155 -149 20 -66 25 -185 9 -195 -8 -5 -125 -9
-261 -9 l-248 0 0 81 c0 101 13 148 58 199 70 83 178 110 287 73z"
            />
            <path
                d="M9960 2681 c-41 -13 -88 -40 -124 -72 l-35 -30 -15 35 c-26 62 -102
78 -147 30 l-24 -26 0 -519 0 -519 27 -27 c34 -34 90 -36 122 -4 21 21 21 31
26 419 l5 398 25 49 c17 34 42 61 74 83 48 32 49 32 168 32 119 0 120 0 149
29 16 16 29 36 29 45 0 28 -22 64 -47 75 -27 12 -194 14 -233 2z"
            />
            <path
                d="M7423 2648 c-44 -49 -43 -54 77 -398 45 -129 88 -253 95 -275 62
-187 138 -394 153 -415 37 -49 105 -64 161 -35 33 16 51 51 97 184 18 53 71
209 119 346 181 524 178 514 166 557 -13 43 -35 58 -86 58 -70 0 -60 24 -302
-723 -26 -81 -52 -146 -57 -144 -6 2 -41 98 -78 213 -196 600 -199 609 -224
632 -17 14 -38 22 -63 22 -26 0 -44 -7 -58 -22z"
            />
            <path
                d="M10303 2644 c-21 -21 -25 -32 -21 -67 3 -23 91 -243 197 -491 105
-247 191 -454 191 -461 0 -11 -37 -119 -129 -373 -23 -63 -41 -127 -41 -144 0
-41 48 -88 91 -88 48 0 85 47 124 160 18 52 84 235 145 405 62 171 127 353
145 405 18 52 65 185 105 295 91 251 103 291 94 318 -24 81 -130 93 -168 19
-7 -15 -59 -153 -115 -307 -141 -388 -152 -415 -160 -415 -9 0 -45 84 -201
465 -58 141 -114 262 -128 278 -35 37 -92 38 -129 1z"
            />
            <path
                d="M2840 735 c0 -308 2 -365 14 -365 9 0 17 15 21 40 l7 39 32 -33 c82
-81 240 -69 306 24 51 71 63 232 25 328 -52 129 -226 163 -327 65 l-38 -37 0
152 c0 145 -1 152 -20 152 -20 0 -20 -7 -20 -365z m308 88 c54 -40 67 -78 67
-193 0 -87 -4 -112 -21 -144 -30 -58 -64 -79 -131 -83 -122 -8 -177 61 -176
221 1 132 34 199 108 219 41 12 126 0 153 -20z"
            />
            <path
                d="M5820 948 l0 -151 -35 36 c-63 65 -178 74 -257 22 -62 -41 -92 -116
-93 -230 0 -108 20 -170 70 -214 79 -69 209 -66 282 6 l33 33 6 -40 c4 -24 12
-40 20 -40 12 0 14 62 14 365 0 358 0 365 -20 365 -19 0 -20 -7 -20 -152z
m-73 -123 c45 -30 67 -98 67 -205 0 -107 -17 -158 -67 -194 -41 -30 -154 -30
-194 0 -55 41 -68 78 -68 194 0 89 3 111 22 146 22 41 51 67 88 77 35 10 126
-1 152 -18z"
            />
            <path
                d="M7220 735 c0 -358 0 -365 20 -365 20 0 20 7 20 365 0 358 0 365 -20
365 -20 0 -20 -7 -20 -365z"
            />
            <path
                d="M6034 1056 c-11 -30 0 -71 20 -71 23 0 36 32 25 63 -9 25 -37 30 -45
8z"
            />
            <path
                d="M4120 715 c0 -338 0 -345 20 -345 19 0 20 8 22 317 l3 316 125 -314
c79 -195 131 -315 141 -317 11 -2 48 80 138 305 68 169 126 311 130 315 3 4 4
-135 3 -307 l-4 -315 26 0 26 0 0 346 0 345 -36 -3 -36 -3 -118 -295 c-65
-162 -122 -295 -126 -295 -4 0 -62 134 -127 298 l-119 297 -34 0 -34 0 0 -345z"
            />
            <path
                d="M7450 716 c0 -339 0 -346 20 -346 19 0 20 8 22 318 l3 317 126 -315
c79 -195 133 -316 142 -318 11 -2 47 79 137 305 68 169 127 312 131 318 5 5 9
-132 9 -308 0 -310 0 -317 20 -317 20 0 20 7 20 345 l0 345 -34 0 -34 0 -119
-297 c-65 -164 -123 -296 -129 -294 -5 2 -61 134 -125 295 l-117 291 -36 3
-36 3 0 -345z"
            />
            <path
                d="M5051 877 c-49 -15 -107 -79 -126 -138 -12 -36 -16 -75 -13 -138 3
-77 7 -94 33 -137 46 -73 98 -99 200 -99 96 0 155 18 155 46 0 17 -4 19 -22
13 -183 -58 -303 -3 -324 150 l-7 46 187 0 186 0 0 33 c-2 172 -121 271 -269
224z m147 -47 c37 -23 71 -88 72 -137 l0 -33 -159 0 -159 0 9 41 c23 101 73
147 164 148 23 1 54 -7 73 -19z"
            />
            <path
                d="M6379 877 c-62 -23 -97 -55 -126 -115 -24 -48 -28 -69 -28 -137 1
-97 16 -145 62 -196 45 -50 121 -74 203 -66 74 8 90 15 90 39 0 16 -4 19 -22
14 -136 -40 -240 3 -277 113 -27 81 -6 218 41 268 46 49 137 64 236 37 17 -5
22 -2 22 14 0 11 -8 23 -17 27 -37 14 -148 15 -184 2z"
            />
            <path
                d="M6790 874 c-63 -17 -72 -24 -61 -42 7 -11 18 -11 61 2 139 41 220 -4
220 -122 l0 -38 -108 -10 c-159 -14 -219 -53 -229 -145 -15 -148 168 -212 294
-104 l41 35 7 -40 c4 -26 12 -40 22 -40 12 0 14 30 11 213 l-3 214 -28 36
c-43 57 -118 70 -227 41z m217 -304 c-3 -33 -14 -75 -24 -92 -36 -64 -147 -97
-213 -63 -37 19 -50 43 -50 93 0 84 61 117 224 121 l69 1 -6 -60z"
            />
            <path
                d="M8380 874 c-46 -20 -87 -59 -113 -109 -18 -33 -22 -57 -22 -140 0
-127 24 -183 100 -230 43 -27 57 -30 129 -30 98 0 156 18 156 47 0 14 -5 18
-16 14 -87 -33 -191 -32 -248 4 -38 23 -71 82 -81 148 l-7 42 186 0 186 0 0
43 c0 95 -40 169 -110 205 -51 25 -110 28 -160 6z m151 -47 c41 -27 69 -79 69
-129 l0 -38 -155 0 -155 0 0 28 c1 60 52 133 110 155 37 14 98 6 131 -16z"
            />
            <path
                d="M3354 818 c13 -35 56 -147 95 -249 39 -102 71 -194 71 -203 0 -10
-15 -51 -34 -92 -38 -81 -64 -100 -123 -90 -27 4 -33 1 -33 -14 0 -51 128 -33
165 24 10 14 75 174 145 356 120 310 126 330 105 330 -19 0 -29 -18 -78 -147
-31 -82 -68 -182 -83 -223 -15 -41 -30 -78 -33 -82 -3 -4 -44 97 -91 223 -76
207 -86 229 -107 229 l-23 0 24 -62z"
            />
            <path
                d="M6040 625 c0 -248 1 -255 20 -255 19 0 20 7 20 255 0 248 -1 255 -20
255 -19 0 -20 -7 -20 -255z"
            />
            <path
                d="M8750 875 c0 -2 38 -58 85 -124 l86 -119 -91 -129 c-81 -115 -88
-128 -68 -131 11 -2 25 2 29 10 23 38 146 202 154 205 6 1 44 -46 85 -105 52
-73 83 -108 98 -110 12 -2 22 -1 22 3 0 3 -40 63 -90 132 l-89 125 46 67 c26
36 64 89 85 118 21 29 38 55 38 58 0 4 -10 5 -22 3 -16 -2 -44 -35 -93 -105
-38 -56 -73 -102 -77 -103 -4 0 -40 47 -80 105 -56 79 -78 104 -95 105 -13 0
-23 -2 -23 -5z"
            />
        </g>
    </svg>
);

export default Logo;
