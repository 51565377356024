// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const rent = {
    id: 'renta',
    title: 'navigation.for_rent',
    type: 'group',
    children: [
        {
            id: 'renta',
            title: 'navigation.for_rent',
            type: 'item',
            url: '/renta',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default rent;
