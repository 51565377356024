import React, { useEffect, useLayoutEffect } from 'react'
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { firestoreConnect, useFirestoreConnect } from 'react-redux-firebase';
import { useTheme } from '@mui/material/styles';
import { Button, Box, Container, Typography, Grid, Rating, List, ListItem, ListItemText, CardContent, Card, IconButton, ListItemButton, ListItemIcon, TextField} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { useParams, useNavigate } from 'react-router';
import MakeHighlights from 'ui-component/highlights/makeHighlights';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { deleteListing } from 'store/actions';
import Slider from 'infinite-react-carousel';

import './listing.css';
import { DescriptionPar } from 'Components/DescriptionPar';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { TabRooms } from '../TabRooms/TabRooms';
import { TypeOfRoomsMasonry } from '../TypeOfRoomsMasonry/TypeOfRoomsMasonry';
import { TypeOfRoomsSelect } from 'Components/TypeOfRoomsSelect';
import CheckAvailableForm from './CheckAvailableForm/CheckAvailableForm';
// import CheckAvailableForm from './CheckAvailableForm/CheckAvailableForm';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const noImageLink =
    'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Fnoimage.jpg?alt=media&token=672b5efb-be9a-48e7-9137-a01c5a56e557';

function ListingHeader({ theme, listing, props, listingId }) {

    const [open, setOpen] = React.useState(false);

    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        const { deleteListing } = props;
        deleteListing(listingId, listing.creator);
        navigate('/user/listings');
    };
    return (
        <>
            <Grid container marginTop={2}>
                <Grid item sm={12}>
                    <Typography variant="h2">{listing.name}</Typography>
                    <Rating readOnly value={2 + Math.random() * 3} precision={0.5} />
                    <Typography marginTop={0} style={{ textTransform: 'uppercase' }}>
                        {listing.type_place}
                    </Typography>
                </Grid>
                <Grid item sm={12}>
                    <Grid container marginTop={2}>
                        <Grid item sm={11}>
                            <Box sx={
                                {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start',
                                    margin: 'auto',
                                    alignItems: 'flex-start',
                                    textAlign: 'center',
                                }
                            }>
                                <MakeHighlights selectedHighlights={listing.highlights} />
                            </Box>
                        </Grid>
                        <Grid item sm={1}>
                            {listing.creator === props.auth.uid ? (
                                <div>
                                    <IconButton
                                        aria-label="edit"
                                        component={NavLink}
                                        color='info'
                                        to={`/listing/edit/${listingId}`}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        color='error'
                                        onClick={handleClickOpen}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <Dialog
                                        open={open}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleClose}
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogTitle>Are you sure you want to delete this listing?</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-slide-description">This cannot be undone.</DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>Cancel</Button>
                                            <Button onClick={handleDelete}>Delete</Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
}

function MakeDetails({ filter, field, listing }) {
    if (['address', 'name', 'description', 'type_place'].includes(field.key)) {
        return null;
    }
    let value = filter.key in listing ? listing[filter.key] : listing;
    value = field.key in value ? value[field.key] : value;
    if (Number.isInteger(value)) {
        return (
            <ListItem disablePadding>
                <ListItemText sx={{ pl: 4 }} primary={field.label} secondary={value} />
            </ListItem>
        );
    }
    if (typeof value === 'string' || value instanceof String) {
        value = field.options ? field.options.find((x) => x.key === value) : null;
        value = value && 'label' in value ? value.label : null;
        return (
            <ListItem disablePadding>
                <ListItemText sx={{ pl: 4 }} primary={field.label} secondary={value} />
            </ListItem>
        );
    }
    return null;
}
function Listing(props) {

    const [openDetails, setOpenDetails] = React.useState([]);
    const handleClick = (index = 0) => {
        let newOpenDetails = openDetails.map((e, idx) => {
            if (idx === index) {
                return !e;
            }
            else {
                return e;
            }
        });

        setOpenDetails(newOpenDetails);
    };

    useLayoutEffect(() => {
        if (filters !== null) {
            filters.map((element) => {
                setOpenDetails(array => [...array, false]);
            })
        }

    }, [props])


    const { listingId } = useParams();
    
    useFirestoreConnect([{ collection: 'listing_info', doc: listingId, storeAs: 'listing' }]);
    
    useFirestoreConnect([
        { 
            collection: `sub-listings`, 
            where:['listingId', '==', listingId],
            storeAs: 'subListings' 
        }
    ]);

    const listing = useSelector((state) => state.firestore.data.listing);
    const subListings = useSelector((state) => state.firestore.data.subListings);

    const theme = useTheme();
    const { creator, filters, categories, types } = props;
    const settings = {
        adaptiveHeight: false,
        autoplay: true,
        autoplaySpeed: 8000,
        centerPadding: 110,
        gutter: 20,
        virtualList: true,
        rows: 1
    };

    useEffect(() => {
    }, [subListings])

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    try {
        if (listing) {
            return (
                <Container>
                    <ListingHeader theme={theme} listing={listing} listingId={listingId} props={props} />
                    <Grid container spacing={2} marginTop={theme.spacing(0.5)}>
                        <Grid item xs={12} md={6}>
                            <>
                                <Slider dots  {...settings}>
                                    {listing.images && listing.images.length > 0 ? (
                                        listing.images.map((image, index) => (
                                            <img
                                                key={`${image}`}
                                                style={{
                                                    flex: 1,
                                                    width: null,
                                                    height: '60vh',
                                                    resizeMode: 'contain'
                                                }}
                                                src={image}
                                                alt="images"
                                            />
                                        ))
                                    ) : (
                                        <img
                                            key="image0"
                                            style={{
                                                flex: 1,
                                                width: null,
                                                height: '60vh',
                                                resizeMode: 'contain'
                                            }}
                                            src={noImageLink}
                                            alt="images"
                                        />
                                    )}
                                </Slider>
                            </>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>

                                <DescriptionPar text={listing.description} style={{ textAlign: 'justify', margin: '0px 15px 15px 15px' }} />

                                <Card style={{ marginTop: 8 }}>
                                    <CardContent>
                                        <>
                                            <Box
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                {listing.pricing && listing.pricing.pricing_general ? (
                                                    <>

                                                        <Typography variant="h3">USD${listing.pricing.pricing_general} avg.</Typography>
                                                        <Typography marginBottom={1} variant='subtitle2'>Per night Room Avg.</Typography>

                                                        {/* <CheckAvailableForm /> */}

                                                    </>
                                                ) : null}
                                            </Box>
                                        </>
                                    </CardContent>
                                </Card>

                                {/* <TabRooms subListing={subListing} /> */}

                            </Box>
                        </Grid>
                        
                        {/* <TypeOfRoomsMasonry subListings={subListings} /> */}
                        
                        <Grid item xs={12} marginBottom={5}>
                            <Grid container spacing={4}>
                                {filters &&
                                    filters.map((filter, index) => (
                                        <>
                                            {['images', 'pricing', 'terms_conditions', 'highlights'].includes(filter.key) ? null : (
                                                <Grid item xs={12} md={3} sm={12} key={filter.label}>
                                                    <Box>
                                                        <List                                                            
                                                            component="nav"
                                                            aria-labelledby="nested-list-subheader"
                                                        >
                                                            <ListItemButton onClick={() => handleClick(index)}>
                                                                <ListItemIcon>
                                                                    <InfoIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary={filter.label} />
                                                                {openDetails[index] ? <ExpandLess /> : <ExpandMore />}
                                                            </ListItemButton>
                                                            <Collapse in={openDetails[index]} timeout="auto" unmountOnExit>
                                                                <List
                                                                    component="nav"
                                                                    aria-labelledby="nested-list-subheader"
                                                                >
                                                                    {filter.fields &&
                                                                        filter.fields.map((field, index) => (
                                                                            <MakeDetails field={field} filter={filter} listing={listing}   key={field.label}/>
                                                                        ))}
                                                                </List>
                                                            </Collapse>
                                                        </List>
                                                    </Box>
                                                </Grid>
                                            )}
                                        </>
                                    ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Featured display={4} /> */}
                </Container>
            );
        }
    } catch (error) {
        console.log(error)
    }
    return <Typography>Loading...</Typography>;
}

const mapDispatchToProps = (dispatch) => ({
    deleteListing: (docId, creator) => dispatch(deleteListing(docId, creator))
});

const mapStateToProps = (state) => {
    const creator = state.firestore.data.creator ? state.firestore.data.creator : null;
    const filters = state.firestore.data.filters ? state.firestore.ordered.filters : null;
    return {
        creator,
        filters,
        auth: state.firebase.auth,
        authError: state.auth.authError
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => {
        const collection = [
            {
                collection: 'listing_filters',
                storeAs: 'filters',
                orderBy: ['order']
            }
        ];
        return collection;
    })
)(Listing);
