import React from 'react';

// material-ui
import { Checkbox, Box, FormControlLabel } from '@mui/material';

import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const changeLanguage = () => {
        if (i18n.language === 'es') {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('es');
        }
    };

    return (
        <>
            <Box component="span" sx={{ marginLeft: '16px' }}>
                <FormControlLabel
                    sx={{
                        color: 'white',
                        textTransform: 'uppercase',
                        fontWeight: 'bold'
                    }}
                    value="end"
                    control={
                        <Checkbox
                            icon={<LanguageIcon />}
                            checkedIcon={<LanguageIcon />}
                            onChange={changeLanguage}
                            disableRipple
                            sx={{
                                color: 'white',
                                '&.Mui-checked': {
                                    color: 'white'
                                }
                            }}
                        />
                    }
                    label={i18n.language}
                    labelPlacement="end"
                />
            </Box>
        </>
    );
};

export default LanguageSelector;
