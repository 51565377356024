import { Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, ImageList, ImageListItem, Skeleton, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { useBookingStore } from 'hooks/useBookingStore';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { SideSearch } from './components/SideSearch';

export const SearchListing = () => {

    const { setSelectedRoom, selectedDaysCount, getDaysFromFormBooking } = useBookingStore();

    const reserve = (room) => {
        setSelectedRoom( room )
    }

    const { availableRooms, selectedBookingForm } = useSelector(state => state.bookings || []);
    useEffect(() => {
        

    }, [ availableRooms ])
    
    useEffect(() => {
      
      const { checkInDate, checkOutDate } = selectedBookingForm || {};
      getDaysFromFormBooking( {checkInDate, checkOutDate} );
      
    }, [selectedBookingForm])
    

    return (
        <>
            <div>
                <Container style={{ marginBottom:'3rem' }}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} marginTop={2}>
                            <Card>
                                <CardContent>
                                    <SideSearch />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={12} marginTop={2}>
                            <Grid container spacing={2}>
                                {availableRooms ? (
                                    <>
                                        {availableRooms.map((element) => (
                                            <Grid item sm={4} key={element.name}>
                                                <Card>
                                                    <CardMedia
                                                        component="img"
                                                        height="250"
                                                        image={element?.recovery?.images[0]}
                                                        alt="Paella dish"
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => {
                                                            window.open(`/listing/${element?.recovery?.name}/${element?.listingId}`, '_blank');                                                            
                                                        }}
                                                    />
                                                    <CardContent>
                                                        <Typography variant="h4">{element?.recovery?.name}</Typography>

                                                        <Box sx={{ marginBottom: 1.5, marginTop: 1.5, display: 'flex' }}>
                                                            <Chip
                                                                label={element.guests}
                                                                variant="outlined"
                                                                size="small"
                                                                color="secondary"
                                                                style={{ marginRight: 5 }}
                                                            />
                                                            <Chip label={element.type} variant="outlined" size="small" color="secondary" />
                                                        </Box>
                                                        <hr />
                                                        <Box display={'flex'} justifyContent={'space-between'} marginBottom={1}>
                                                            <Typography sx={{ mb: 1.5 }} color="text.black">
                                                                ${element.generalPrice} per day
                                                            </Typography>
                                                            <Typography sx={{ mb: 1.5 }} color="text.black">
                                                                ${element.generalPrice} x {selectedDaysCount} = $
                                                                {element.generalPrice * selectedDaysCount}
                                                            </Typography>
                                                        </Box>
                                                        {/* <Button 
                                                            component={ Link }
                                                            to={`/listing/${element?.recovery?.name}/${element?.listingId}`}
                                                            color='secondary'
                                                            target="_blank" 
                                                            variant="outlined" 
                                                            size="small" 
                                                            style={{ marginRight:10 }} 
                                                        >
                                                            Info
                                                        </Button> */}
                                                        <Box display={'flex'} justifyContent={'space-between'} >
                                                            <div>
                                                                <Typography variant='h4' >{element.type_place}</Typography>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    variant="outlined"
                                                                    size="small"
                                                                    color="secondary"
                                                                    onClick={() => reserve(element)}
                                                                >
                                                                    Reserve
                                                                </Button>
                                                            </div>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                ) : (
                                    <Box sx={{ width: '100%', marginTop: 2, marginLeft: 2 }}>
                                        <Skeleton />
                                        <Skeleton animation="wave" />
                                        <Skeleton animation={false} />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
}
