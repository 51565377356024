import { useTheme } from '@emotion/react';
import { Alert, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide } from '@mui/material'
import { Container } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid';
import React, { forwardRef } from 'react'
import { connect, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { NavLink, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { deleteSublisting } from 'store/actions';

const SubListings = (props) => {

    const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

    const theme = useTheme();
    
    const { creator, filters, categories, types, deleteSublisting } = props;

    const { listingId } = useParams();

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        deleteSublisting(docId, '');
    };

    const handleDeleteSubListing = ( docId ) => {

        setOpen(true);

    };

    useFirestoreConnect([
                            { 
                                collection: `sub-listings`, 
                                where:['listingId', '==', listingId],
                                storeAs: 'subListings' 
                            }
                        ]);

    const subListings = useSelector((state) => {
        let subListings = state.firestore.data.subListings;
        if(subListings){
            let data = Object.keys(subListings).map(key => {
                return {
                    ...subListings[key], id:key
                };
            });
            return data ? data : null;
        }
    });

    const columns = [
        { field: 'id', headerName:'UID', width:100},
        { field: 'name', headerName: 'Room', width: 250 },
        { field: 'price', headerName: 'Price', width: 250 },
        { field: 'generalPrice', headerName: 'General price', width: 250 },
        {
            field: "action",
            width: 250,
            headerName: "Actions",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                const api = params.api;
                const thisRow = {};
        
                api
                    .getAllColumns()
                    .filter((c) => c.field !== "__check__" && !!c)
                    .forEach(
                    (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                    );

                    const { id } = thisRow;

                return id;
                };
        
                return <>
                        <Button
                                variant='outlined' 
                                size='small'
                                style={{marginRight:10}}
                                component={NavLink}
                                to={`/listing/sub-listings/save?listingId=${listingId}&sub-listingId=${onClick()}`}
                                >
                                    Update
                        </Button>
                        <Button
                                variant='outlined' 
                                size='small'
                                color='error'
                                onClick={() => {
                                    let id = onClick();
                                    handleDeleteSubListing(id);
                                }}
                                >
                                    Delete
                        </Button>
                </>
            }
        }
    ];

    return (
        <>
            <Container style={{marginTop:'25px', marginBottom:'10vh'}}>
                <Grid container >
                    <Grid item sm={12}>
                        <h2>Sub listings</h2>
                    </Grid>
                    <Grid item sm={12}>
                        <Card >
                            <CardContent>
                                <div style={{ marginBottom: 15 }}>
                                    <Button
                                        component={NavLink}
                                        to={`/listing/sub-listings/save?listingId=${listingId}&sub-listingId=new`}
                                        color='success'
                                        style={{ marginBottom: '1rem', marginRight:'1rem' }}
                                        variant='outlined'
                                    >
                                        Add sub listing
                                    </Button>
                                    <Button
                                        component={NavLink}
                                        to={`/user/listings`}
                                        color='info'
                                        style={{ marginBottom: '1rem' }}
                                        variant='outlined'
                                    >
                                        Return to listings
                                    </Button>

                                    {    subListings ?
                                       ( <DataGrid
                                            autoHeight={true}
                                            columns={columns}
                                            disableExtendRowFullWidth={true}
                                            rows={subListings}
                                        />
                                        )
                                        :
                                        (
                                            <div style={{ marginTop:'1rem' }}>
                                                {
                                                    subListings !== null &&
                                                        (
                                                            <Alert variant="outlined" severity="info">
                                                                There are not data loaded...
                                                            </Alert>
                                                        )
                                                }
                                            </div>    
                                        )

                                    }
                  
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={open}
                 keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Are you sure you want to delete this listing?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">This cannot be undone.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    )
   
}

const mapDispatchToProps = (dispatch) => ({
    deleteSublisting: (docId, creator) => dispatch(deleteSublisting(docId, creator))
});

const mapStateToProps = (state) => {
    const creator = state.firestore.data.creator ? state.firestore.data.creator : null;
    const filters = state.firestore.data.filters ? state.firestore.ordered.filters : null;
    return {
        creator,
        filters,
        auth: state.firebase.auth,
        authError: state.auth.authError
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
   )(SubListings);
