
const initState = {
    authError: null
};

function convertToSlug(Text) {
    return Text.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SIGNUP_ERROR':
            console.log('register error', action.err);
            return {
                ...state,
                authError: action.err
            };
        case 'SIGNUP_SUCCESS':
            console.log('register success');
            return {
                authError: null
            };
        case 'LOGIN_ERROR':
            console.log('login error');
            return {
                ...state,
                authError: action.err
            };
        case 'LOGIN_SUCCESS':
            console.log('login success');
            return {
                authError: null
            };
        case 'SIGNOUT_SUCCESS':
            console.log('signout success');
            return state;
        case 'SAVE_LISTING_SUCCESS':
            console.log('save listing succesful');
            console.log(action.docId);
            return { docId: action.docId };
        case 'SAVE_LISTING_ERROR':
            console.log('save listing error', action.err);
            console.log(state);
            return state;
        case 'EDIT_LISTING_SUCCESS':
            console.log('EDIT_LISTING_SUCCESS');
            return { listingEdit: true };
        case '[Auth] Set Roles':
            return { ...state, authRoles: action.payload};
        default:
            return state;
    }
};

export default authReducer;
