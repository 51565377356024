import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";

export const catalogs = (props) => {

    useLayoutEffect(() => {
        const { getGuestsTypes } = props;
        getGuestsTypes();
    },[props]);

    const guestsTypes = () => {
        const { guests } = useSelector( state => state);

        return guests?.data || [];
    };

    return {
        guestsTypes
    }
}
