
import firebase from '../config/fbConfig';

const firestore = firebase.firestore();

export const getRecoveryTypes = async () => {

    try {
        const data = await firestore
        .collection('recoverytypes')
        .orderBy('name')
        .get()
        .then(x => {
            const res = x.docs.map((doc) => doc.data())
            return res;
        });

    return data || [];

    } catch (error) {
        console.log(error);
    }

};