
import {
    Avatar,
    Box,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material';

import PerfectScrollbar from 'react-perfect-scrollbar';

import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

import { IconLogout, IconSettings, IconCalendarTime, IconHome2, IconHome, IconCalendar } from '@tabler/icons';
import { useTheme } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAuthInformation } from 'hooks/useAuthInformation';


export const AdminSection = () => {

    const { auth, userData } = useAuthInformation();
    const theme = useTheme();
    const { t } = useTranslation();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

  return (
      <div>
          <>
              <Avatar
                  sx={{
                      ...theme.typography.mediumAvatar,
                      bgcolor: theme.palette.secondary.main,
                      cursor: 'pointer',
                      color: 'white'
                  }}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
              >
                  {userData && userData.initials ? userData.initials : null}
              </Avatar>
              <Popper
                  placement="bottom-end"
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  popperOptions={{
                      modifiers: [
                          {
                              name: 'offset',
                              options: {
                                  offset: [0, 14]
                              }
                          }
                      ]
                  }}
              >
                  {({ TransitionProps }) => (
                      <Transitions in={open} {...TransitionProps}>
                          <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                  <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                      <Box sx={{ p: 2 }}>
                                          <Stack>
                                              <Stack direction="row" spacing={0.5} alignItems="center">
                                                  <Typography variant="h4">Good Morning,</Typography>
                                                  <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                      {userData.firstName} {userData.lastName}
                                                  </Typography>
                                              </Stack>
                                              <Typography variant="subtitle2" marginTop={1}>
                                                  Roles: {NameRole(userData.roles)}
                                              </Typography>
                                          </Stack>
                                      </Box>
                                      <Divider />
                                      <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                          <Box sx={{ p: 2 }}>
                                              <List
                                                  component="nav"
                                                  sx={{
                                                      width: '100%',
                                                      maxWidth: 350,
                                                      minWidth: 300,
                                                      backgroundColor: theme.palette.background.paper,
                                                      borderRadius: '10px',
                                                      [theme.breakpoints.down('md')]: {
                                                          minWidth: '100%'
                                                      },
                                                      '& .MuiListItemButton-root': {
                                                          mt: 0.5
                                                      }
                                                  }}
                                              >
                                                  {/* user settings */}
                                                  {isAdmin(userData?.roles || []) && (
                                                      <>
                                                          <ListItemButton
                                                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                              selected={selectedIndex === 0}
                                                              onClick={(event) => handleListItemClick(event, 0, '/user/account')}
                                                          >
                                                              <ListItemIcon>
                                                                  <IconSettings stroke={1.5} size="1.3rem" />
                                                              </ListItemIcon>
                                                              <ListItemText
                                                                  primary={<Typography variant="body2">Account Settings</Typography>}
                                                              />
                                                          </ListItemButton>

                                                          <ListItemButton
                                                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                              selected={selectedIndex === 0}
                                                              onClick={(event) =>
                                                                  handleListItemClick(event, 0, '/user/calendar-reservation')
                                                              }
                                                          >
                                                              <ListItemIcon>
                                                                  <IconCalendar stroke={1.5} size="1.3rem" />
                                                              </ListItemIcon>
                                                              <ListItemText
                                                                  primary={<Typography variant="body2">Calendar reservation</Typography>}
                                                              />
                                                          </ListItemButton>

                                                          {/* my listings */}
                                                          <ListItemButton
                                                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                              selected={selectedIndex === 0}
                                                              onClick={(event) => handleListItemClick(event, 0, '/user/listings')}
                                                          >
                                                              <ListItemIcon>
                                                                  <IconHome2 stroke={1.5} size="1.3rem" />
                                                              </ListItemIcon>
                                                              <ListItemText
                                                                  primary={<Typography variant="body2">My Listings</Typography>}
                                                              />
                                                          </ListItemButton>

                                                          {/* logout */}
                                                          <ListItemButton
                                                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                              selected={selectedIndex === 4}
                                                              // onClick={props.signOut}
                                                          >
                                                              <ListItemIcon>
                                                                  <IconLogout stroke={1.5} size="1.3rem" />
                                                              </ListItemIcon>
                                                              <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                          </ListItemButton>
                                                      </>
                                                  )}

                                                  {isGeneral(userData?.roles || []) && (
                                                      <>
                                                          <ListItemButton
                                                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                              selected={selectedIndex === 0}
                                                              onClick={(event) => handleListItemClick(event, 0, '/user/account')}
                                                          >
                                                              <ListItemIcon>
                                                                  <IconSettings stroke={1.5} size="1.3rem" />
                                                              </ListItemIcon>
                                                              <ListItemText
                                                                  primary={<Typography variant="body2">Account Settings</Typography>}
                                                              />
                                                          </ListItemButton>

                                                          {/* my reservations */}
                                                          <ListItemButton
                                                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                              selected={selectedIndex === 0}
                                                              onClick={(event) => handleListItemClick(event, 0, '/user/listings')}
                                                          >
                                                              <ListItemIcon>
                                                                  <IconCalendarTime stroke={1.5} size="1.3rem" />
                                                              </ListItemIcon>
                                                              <ListItemText
                                                                  primary={<Typography variant="body2">My Reservations</Typography>}
                                                              />
                                                          </ListItemButton>

                                                          {/* logout */}
                                                          <ListItemButton
                                                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                              selected={selectedIndex === 4}
                                                              // onClick={props.signOut}
                                                          >
                                                              <ListItemIcon>
                                                                  <IconLogout stroke={1.5} size="1.3rem" />
                                                              </ListItemIcon>
                                                              <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                          </ListItemButton>
                                                      </>
                                                  )}
                                              </List>
                                          </Box>
                                      </PerfectScrollbar>
                                  </MainCard>
                              </ClickAwayListener>
                          </Paper>
                      </Transitions>
                  )}
              </Popper>
          </>
      </div>
  );
}

const NameRole = (roles) => {
        let concat = '';
        roles.forEach(role => {
            concat = concat + `${role} `;
        });
        
        return concat;
}

const isAdmin = (roles) => {

    let isAdminRole = false;

    roles.forEach(role => {
        if(role === 'ADMIN'){
            isAdminRole = true;
        }
    });

    return isAdminRole;

}

const isGeneral = (roles) => {

    let isAdminRole = false;

    roles.forEach(role => {
        if(role === 'GENERAL'){
            isAdminRole = true;
        }
    });

    return isAdminRole;

}