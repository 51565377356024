// import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Routes, Route } from 'react-router-dom';

// routes
import MinimalLayout from 'layout/MinimalLayout';
import SubListings  from 'views/sub-listing/SubListings';
import SaveSubListing from 'views/sub-listing/SaveSubListing/SaveSubListing';

// ==============================|| ROUTING RENDER ||============================== //
// landing routing
const LandingDefault = Loadable(lazy(() => import('views/landing')));
// search routing
const SearchDefault = Loadable(lazy(() => import('views/search/SearchListing')));

// listing routing
const EditListing = Loadable(lazy(() => import('views/listing/editListing')));
const CreateListing = Loadable(lazy(() => import('views/listing/createListing')));
const Listing = Loadable(lazy(() => import('views/listing/listing')));

// User
const UserHeader = Loadable(lazy(() => import('views/user/userHeader')));
const UserListings = Loadable(lazy(() => import('views/user/userListings')));
const UserSettings = Loadable(lazy(() => import('views/user/settings')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

import { ListingsRoutes } from "./ListingsRoutes";
import { PrivateRoute } from './PrivateRoute';
import { SearchListing } from 'views/search/SearchListing';
import { useSelector } from 'react-redux';
import { useAuthFirebase } from 'hooks/useAuthFirebase';
import { CalendarReservationApp } from 'views/CalendarReservation/CalendarReservationApp';

export default function ThemeRoutes() {

    const auth = useSelector((state) => state.firebase.auth);

    const { setAuthRoles } = useAuthFirebase();

    if(auth){

        setAuthRoles();

    }    

    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route index element={<LandingDefault />} />
                <Route path="search" element={<SearchListing />} />
                <Route path='/*' element={
                    <PrivateRoute>
                        <ListingsRoutes/>
                    </PrivateRoute>
                    }/>
                <Route path="user" element={<UserHeader />}>
                    <Route path="account" element={<UserSettings />} />
                    <Route path="listings" element={<UserListings />} />
                    <Route path="calendar-reservation" element={<CalendarReservationApp />} />
                </Route>
            </Route>
            <Route path="/" element={<MinimalLayout />}>
                <Route index element={<LandingDefault />} />
                <Route path="login" element={<AuthLogin3 />} />
                <Route path="register" element={<AuthRegister3 />} />
            </Route>
        </Routes>
    );
    // useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
