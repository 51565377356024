import { SubListingModel } from 'models/sublisting.model';
import React, { useState } from 'react'
import firebase from '../config/fbConfig';

export const useSubListings = () => {
    
    const firestore = firebase.firestore();

    const [subListings, setSubListings] = useState([]);

    const getSublistings = async ( listingId = '') => {

        if( listingId === ''){
            setSubListings([]);
            return;
        }

        const data = await firestore
        .collection('sub-listings')
        .where('listingId', '==', listingId)
        .get().then((x) => { 
            return x.docs.map((doc) => { return {...doc.data(), id:doc.id} }) 
        });

        setSubListings(data);

    }

    const getSublisting = async ( id ) => {
        if ( id === '' ) return;
        
        const data = await firestore
            .collection('sub-listings')
            .doc(id)
            .get()
            .then((snapshot) => {
                return snapshot.data();
            })
            .catch((e) => console.log(e));
        
            let listing = new SubListingModel();

            listing = { ...data };

            return listing;

    }

    return {
        getSublistings, subListings, getSublisting
    }
}
