import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import firebase from '../config/fbConfig';

export const useAuthFirebase = () => {
    
    const firestore = firebase.firestore();
    
    const auth = useSelector((state) => state.firebase.auth);

    const dispatch = useDispatch();

    useEffect(() => {}, [auth])

    const setAuthRoles = async () => {

        const { uid } = auth;

        if( uid ){
            const user = await firestore.collection('users')
            .doc( uid )
            .get()
            .then( docRef => docRef.data() )

            const { roles } = user;
            
            const action = {
                type:'[Auth] Set Roles',
                payload : roles
            }

            dispatch( action );
            

        }

    }
    
    return {

        setAuthRoles

    }

}