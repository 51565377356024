export class ListingModel{
    constructor(
        elevator,
        gated,
        posted,
        description,
        nearest_hospital,
        name,
        security,
        terms_conditions,
        address,
        building_type,
        creator,
        type_stay,
        images,
        type_place,
        general_specifications,
        nearest_border,
        nearest_airport,
        pricing,
        care_experience,
        location_capacity,
        highlights
    ){
        this.elevator = elevator;
        this.gated = gated;
        this.posted = posted;
        this.description = description;
        this.nearest_hospital = nearest_hospital;
        this.name = name;
        this.security = security;
        this.terms_conditions = terms_conditions;
        this.address = address;
        this.building_type = building_type;
        this.creator = creator;
        this.type_stay = type_stay;
        this.images = images;
        this.type_place = type_place;
        this.general_specifications = general_specifications;
        this.nearest_border = nearest_border;
        this.nearest_airport = nearest_airport;
        this.pricing = pricing;
        this.care_experience = care_experience;
        this.location_capacity = location_capacity;
        this.highlights = highlights;
    }
}