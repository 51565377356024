import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router";
import { getDaysByTwoDates } from "store/actions";

export const useBookingStore = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
   
    const [selectedDaysCount, setSelectedDaysCount] = useState(0);

    const { selectedBookingForm } = useSelector( state =>  state.bookings || { } );

    const setSelectedRoom = (room) => {
        const {generalPrice, listingId, id:roomId, recovery, guests} = room;
        let bookingDays = [];
        let totalCashDays = 0;
        
        let { checkInDate, checkOutDate } = selectedBookingForm;

        checkInDate = moment(checkInDate).startOf('day').toDate();
        checkOutDate = moment(checkOutDate).endOf('day').toDate();

        const days = getDaysByTwoDates(checkInDate, checkOutDate);
                        
                        days.forEach((element) => {
                            totalCashDays =  generalPrice + totalCashDays;
    
                            bookingDays = [
                                ...bookingDays,
                                {
                                    date: element,
                                    listingId,
                                    roomId
                                }
                            ];
                        });
                        const action = {
                            type:'[Bookings] Selected Room',
                            payload:{
                                bookingDays,
                                booking:{
                                    listingId, checkInDate, checkOutDate, roomId, guests,
                                    totalCashDays, ...recovery, generalPrice, days:days.length
                                }
                            }
                        }
    
                        dispatch(action);
         
                        navigate('/chekings/checkIn')

                        return;
    }

    const getDaysFromFormBooking = ({checkInDate,checkOutDate}) => {
        const days = getDaysByTwoDates(checkInDate, checkOutDate);

        setSelectedDaysCount( days.length );
    }

    return{
        setSelectedRoom,
        selectedDaysCount,
        getDaysFromFormBooking
    }

}
