import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Hidden } from '@mui/material';

// project imports
import menuItem from './menu-items';

// ===========================|| MAIN MENU ||=========================== //
const MenuSection = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <div>
            <Hidden lgDown>
                {menuItem.items.map((category) =>
                    category.children.map((item) => (
                        <Link
                            variant="button"
                            to={item.url}
                            key={`link_${item.key}`}
                            style={{
                                fontSize: theme.spacing(2),
                                color: '#FFFFFf',
                                margin: theme.spacing(1, 1.5),
                                letterSpacing: theme.spacing(0.2),
                                textDecoration: 'none',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: '#FFFFFf',
                                    fontWeight: 1000,
                                    textDecoration: 'none',
                                    boxShadow: 'inset 0 -3px 0 0 #FFFFFf'
                                }
                            }}
                        >
                            {t(item.title)}
                        </Link>
                    ))
                )}
                {/* <Button
                    variant="outlined"
                    color="secondary"
                    style={{ color: 'white', border: '2px solid white', fontWeight: 'bold' }}
                    component={NavLink}
                    to="/listing/create-listing"
                >
                    {t('navigation.create_listing')}
                </Button> */}

            </Hidden>

        </div>
    );
};

export default MenuSection;
