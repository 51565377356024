import React from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, IconButton, List, ListItem, ListItemButton, ListItemText, MenuItem, Toolbar, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import { Box } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import { menuFooter } from 'views/landing/data/landing-data';
import { Link, NavLink } from 'react-router-dom';

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  });

export const FooterMenuMobile = () => {
  return (
    <div>
          <AppBar position="fixed" color="inherit" sx={{ top: 'auto', bottom: 0 }}>
              <Toolbar sx={
                          {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            alignItems:'center',
                            textAlign:'center'
                          }
                      }>
                   
                          {
                              menuFooter.map((element, idx) => (
                                  <Link style={{ textDecoration: 'none', color: 'black' }}
                                      component={NavLink}
                                      key={element.name}
                                      to={`${element.url}`}>
                                      <img
                                          src={element.urlIcon}
                                          alt={element.title}
                                          loading="lazy"
                                          key={element.name + idx}
                                          style={{ width: 30 }}
                                      />
                                      <Typography>
                                          {
                                              element.title
                                          }
                                      </Typography>

                                  </Link>
                              ))
                          }
              </Toolbar>
          </AppBar>
    </div>
  )
}
