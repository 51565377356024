import React, { useEffect, useRef, useState } from 'react'
import { Button, FormControl, InputLabel, TextField, Select, MenuItem, Typography } from '@mui/material'
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useFirebase } from 'hooks/useTypes';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useAvailability } from 'hooks/useAvailability';
import Swal from 'sweetalert2';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { checkInAvailableRooms, getGuestsTypes } from '../../../../store/actions';
import { useBookings } from 'hooks/useBookings';
import { catalogs } from 'views/sub-listing/hooks/catalogs';

const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
    return (
        <Select
            name={name}
            value={value}
            defaultValue={""}
            onChange={e => {
                setFieldValue(name, e.target.value);
            }}
            style={{ marginBottom: 15 }}
        >
            {children}
        </Select>
    );
};

const CheckAvailableForm = (props) => {

    const [formBooking, setFormBooking] = useState({    
        type: '',
        checkInDate: new Date(),
        checkOutDate: new Date()
    });

    const formikRef = useRef();

    const dispatch = useDispatch();

    const { checkInAvailableRooms } = props;
    
    const { listingId } = useParams();

    const { fbData } = useFirebase('typeRooms');

    const { getFirebase, checkAvailabilityByDate } = useAvailability(listingId);

    const { bookings } = useBookings();
    
    const navigate = useNavigate();

    const handleNavigateCheckIn = () => {

        if(formBooking){
            const { uid } = formBooking;
            navigate(`/chekings/checkIn?listingId=${listingId}&subListingId=${uid}`);
        }

    };

    useEffect(() => {
        if(bookings?.data){
            
            const { booking:bookingData } = bookings?.data;
            setFormBooking({...formBooking, ...bookingData})
            return;
        }
        
    }, [bookings])
    
    const { guestsTypes } = catalogs(props);
  
    const guests = guestsTypes();
        
    return (
        <>
            <Formik
                initialValues={ formBooking }           
                innerRef={ formikRef }     
                enableReinitialize
                validationSchema={Yup.object().shape({
                    type: Yup.string().required('Type is require'),
                    // checkInDate: Yup.date().required('Check in is required'),
                    // checkOutDate: Yup.date().required('Check out is required')
                })}

                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    
                    const { type, checkInDate, checkOutDate } = values;
                    
                    const rooms = getFirebase(type);
                    
                    
                    if (rooms.length > 0) {
                        
                        checkInAvailableRooms({ listingId, checkInDate, checkOutDate, room:rooms[0] })

                    } else {
                        Swal.fire({
                            title: 'Rooms not availables',
                            text: 'Try with other room',
                            icon: 'info',
                            confirmButtonText: 'Ok'
                        }).then(() => {
                            dispatch({
                                type:'[Bookings] Reserve Booking',
                                payload:[]
                            });
                            
                        });
                    }

                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    onChange={(value) => setFieldValue("checkInDate", value, true)}
                                    value={values.checkInDate}
                                    label="check in date"
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(touched.checkInDate && errors.checkInDate)}
                                            helperText={touched.checkInDate && errors.checkInDate}
                                            margin="normal"
                                            name="checkInDate"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>

                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    onChange={(value) => setFieldValue("checkOutDate", value, true)}
                                    value={values.checkOutDate}
                                    label="check out date"
                                    renderInput={(params) => (
                                        <TextField
                                            error={Boolean(touched.checkOutDate && errors.checkOutDate)}
                                            helperText={touched.checkOutDate && errors.checkOutDate}
                                            margin="normal"
                                            name="checkOutDate"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>

                        <FormControl
                            fullWidth
                            style={{ marginTop: '1rem' }}
                        >
                            <InputLabel id="demo-simple-select-label">Guests</InputLabel>
                            <Field
                                error={errors.type && touched.type}
                                name="type"
                                id='type'
                                labelId="demo-simple-select-label"
                                value={values.type}
                                label="Type"
                                onChange={handleChange}
                                component={CustomizedSelectForFormik}>
                                {guests &&
                                    guests.map((element) => (
                                        <MenuItem
                                            id={element.type}
                                            name={element.type}
                                            key={element.type}
                                            value={element.type || ""}
                                        >
                                            {element.type}
                                        </MenuItem>
                                    ))}
                            </Field>
                        </FormControl>

                        <Button
                            size="medium"
                            color='info'
                            style={{ marginTop: 15, marginRight:10 }}
                            variant='outlined'
                            type='submit'
                        >
                            Check availability
                        </Button>
                        {
                            bookings?.bookingDays?.length > 0  &&
                            <Button
                                color='success'
                                onClick={handleNavigateCheckIn}
                                size="medium"
                                style={{ marginTop: 15 }}
                                variant='outlined'
                                type='button'
                            >
                                Confirm booking
                            </Button>
                        }

                    </form>
                )}
            </Formik>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    checkInAvailableRooms: (data) => dispatch(checkInAvailableRooms(data)),
    getGuestsTypes: () => dispatch(getGuestsTypes())
});

const mapStateToProps = (state) => {
    const creator = state.firestore.data.creator ? state.firestore.data.creator : null;
    const filters = state.firestore.data.filters ? state.firestore.ordered.filters : null;
    return {
        creator,
        filters,
        auth: state.firebase.auth,
        authError: state.auth.authError
    };
};

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => {
        const collection = [
          
        ];
        return collection;
    })
)(CheckAvailableForm);
