
const guestsReducer = (initialState = null, action) => {
    switch (action.type) {
        case '[Guests] Get Guests':            
            return { ...initialState, data: action.payload};
        default:
            return initialState;
    }
};

export default guestsReducer;
