import { useEffect } from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Hidden } from '@mui/material';

import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

import Footer from './Footer';
import LogoSection from './LogoSection';
import MenuSection from './Header/MenuSection';
import LanguageSelector from './Header/LanguageSection';
import { MenuResponsive } from './Header/MenuResponsive/MenuResponsive';
import ProfileSection from './Header/ProfileSection';
import { FooterMenuMobile } from './Header/FooterMenuMobile/FooterMenuMobile';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const { auth } = props;
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
    }, [matchDownMd]);
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position='static'
                    sx={{
                        borderTop: `5px solid ${theme.palette.secondary.main}`,
                        bgcolor: theme.palette.primary.main,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar>
                        <LogoSection sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        <MenuSection />
                        <Box sx={{ flexGrow: 1 }} />
                        <Hidden lgDown>
                            <LanguageSelector />
                        </Hidden>

                        <Hidden lgUp>
                            <MenuResponsive />
                        </Hidden>

                        <Box sx={{ display: { md: 'flex' }, zIndex: 5 }}>
                            <ProfileSection auth={auth} />
                        </Box>
                    </Toolbar>

                </AppBar>
            </Box>
            <Box sx={{ display: 'flex' }} >
                <Main theme={theme} open={leftDrawerOpened} style={{ marginTop: 0 }} >
                    <CssBaseline />
                    <Outlet />
                    <Footer />
                </Main>
            </Box>
            <Hidden xlUp>
                <FooterMenuMobile />
            </Hidden>
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.firebase.auth
});

export default compose(connect(mapStateToProps))(MainLayout);
