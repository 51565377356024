import styled from '@emotion/styled';
import { Masonry } from '@mui/lab';
import { Card, CardMedia, ImageList, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        cursor: 'pointer'
    },
    font: {
        position: "absolute",
        top: "200%",
        width: "100%",
        textAlign: "center",
        color: "black",
        backgroundColor: "none",
        fontFamily: "Comic Sans MS"
    }
}));
const styleCard = {
    height: '500px',
    '&:hover':{
        height:'1000px'
    }
};
const settings = {
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 8000,
    centerPadding: 110,
    gutter: 20,
    virtualList: true,
    rows: 1
};


export const TypeOfRoomsMasonry = ({ subListings = {} }) => {

    const [imgCategories, setImgCategories] = useState([]);
    const [shadow, setShadow] = useState(1);

    const onMouseOver = () => setShadow(3);
    const onMouseOut = () => setShadow(1);

    useEffect(() => {
        if (subListings) {

            let categories = [];
            Object.keys(subListings).forEach(key => {
                categories.push({
                    urlImg: subListings[key]?.images[0],
                    cat: subListings[key]?.type
                })
            })

            setImgCategories(categories)
        }
    }, [subListings])

    return (
        <>
            <Box sx={{ marginTop: 3 }}>
                <Masonry spacing={2}>
                {/* <ImageList sx={{ width: 500, height: 450 }}  > */}
                {/* <Slider dots  {...settings}></Slider> */}
                    {imgCategories && imgCategories.map((obj, index) => (
                        <Card 
                            className={useStyles.root}
                            onMouseOut={onMouseOver}
                            onMouseOver={onMouseOut}
                            key={obj.urlImg}
                        >
                            <div style={{ position: "relative" }} >
                                <CardMedia
                                    style={{ paddingTop: "0%", cursor: 'pointer' }}
                                    component="img"
                                    image={obj.urlImg}
                                    title={obj.cat}
                                    alt={obj.cat} />
                                <div style={{
                                    position: "absolute",
                                    color: "black",
                                    fontWeight: "bold",
                                    top: 20,
                                    left: "50%",
                                    transform: "translateX(-50%)"
                                }}
                                >
                                    <Typography sx={{ fontSize: 10, textAlign: 'center' }}>{obj.cat}</Typography>
                                    <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>{obj.cat}</Typography>
                                </div>
                            </div>
                        </Card>
                    ))}
                {/* </ImageList> */}

                </Masonry>
            </Box>
        </>
    )
}
