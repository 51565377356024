import React from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { Bookings } from 'views/SelectBooking/Bookings'
import CreateListing from 'views/listing/createListing'
import EditListing from 'views/listing/editListing'
import Listing from 'views/listing/listing'
import SaveSubListing from 'views/sub-listing/SaveSubListing/SaveSubListing'
import SubListings from 'views/sub-listing/SubListings'
import { BookingCheck } from 'views/BookingCheck/BookingCheck'
import { ChekingMain } from 'views/Checking/ChekingMain'

export const ListingsRoutes = () => {

    return (
        <>
        <Routes>
            <Route path="listing" element={<Outlet />}>
                <Route path="create-listing" element={<CreateListing />} />
                <Route path="edit/:listingId" element={<EditListing />} />
                <Route path=":description/:listingId" element={<Listing />} />
                <Route path='sub-listings/:listingId' element={<SubListings />} />
                <Route path='sub-listings/save' element={<SaveSubListing />} />
            </Route>
            <Route path="bookings" element={<Outlet />}>
                <Route path="booking-calendar" element={<Bookings />} />                
                <Route path="booking-check" element={<BookingCheck />} />                
            </Route>
            <Route path="chekings" element={<Outlet />}>
                <Route path="checkIn" element={<ChekingMain />} />                
            </Route>
        </Routes>
        </>
    )
}

