import { Alert, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid';
import { useCalendarReservations } from 'hooks/useCalendarReservations';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormCalendar } from './FormCalendar';
import { ManualModalReservation } from './ManualModalReservation';


export const CalendarReservationApp = () => {
  
    const { reservations, getCalendarReservations } = useCalendarReservations();

    const { paysByListing } = useSelector( state => state.bookings || []);

    useEffect(() => {
        
    }, [paysByListing])
    
    const columns = [
        { field: 'id', headerName: 'UID', width: 100 },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => {
                const api = params.api;

                const { surname, given_name } = params?.formattedValue || {};

                return `${given_name} ${surname}`;
            }
        },
        { field: 'status', headerName: 'Status pay', width: 130 },
        {
            field: 'checkInDate',
            headerName: 'Check in',
            width: 100,
            renderCell: (params) => {
                const date = new Date(params?.formattedValue.seconds * 1000);
                return moment(date).format('DD-MM-YYYY');
            }
        },
        {
            field: 'checkOutDate',
            headerName: 'Check out',
            width: 100,
            renderCell: (params) => {
                const date = new Date(params?.formattedValue.seconds * 1000);
                return moment(date).format('DD-MM-YYYY');
            }
        },
        {
            field: 'createdDate',
            headerName: 'Created date',
            width: 130,
            renderCell: (params) => {
                const date = new Date(params?.formattedValue.seconds * 1000);
                return moment(date).format('DD-MM-YYYY HH:mm');
            }
        },
        { field: 'totalCashDays', headerName: 'Cash', width: 80 },
        { field: 'days', headerName: 'Days', width: 80 },
        {
            field: 'action',
            width: 250,
            headerName: 'Actions',
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    const api = params.api;
                    const thisRow = {};

                    api.getAllColumns()
                        .filter((c) => c.field !== '__check__' && !!c)
                        .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

                    const { id } = thisRow;

                    return id;
                };

                return (
                    <>
                        <Button variant="outlined" size="small" style={{ marginRight: 10 }}>
                            Info
                        </Button>
                        <Button variant="outlined" size="small" color="error">
                            Check
                        </Button>
                    </>
                );
            }
        }
    ];

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    return (
        <>
            <Container style={{ marginBottom: '10vh' }}>
                <Grid container spacing={2}>
                    <Grid item sm={3}>
                        <Card>
                            <CardContent>
                                <FormCalendar />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={9}>
                        <Card>
                            <div style={{ marginLeft:'2rem', marginTop:'1rem ' }}>
                                <Button onClick={handleOpen} color="primary" variant="contained" type="button">
                                    Add new
                                </Button>
                            </div>
                            <CardContent>
                                
                                <div style={{ marginBottom: 15 }}>
                                    {paysByListing ? (
                                        <DataGrid
                                            autoHeight={true}
                                            columns={columns}
                                            disableExtendRowFullWidth={true}
                                            rows={paysByListing}
                                        />
                                    ) : (
                                        <div style={{ marginTop: '1rem' }}>
                                            {paysByListing !== null && (
                                                <Alert variant="outlined" severity="info">
                                                    There are not data loaded...
                                                </Alert>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <ManualModalReservation setOpen={setOpen} open={open} />
        </>
    );
};
