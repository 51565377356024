import { Grid } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react'
import Background from '../../assets/images/views/bookings-pic.jpg';
import { BookingDetail } from './BookingDetail';
import { BookingSearchBar } from './BookingSearchBar';
export const Bookings = () => {
    const imgBanner = `url(https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Fbookings%2Fempty-pavilion-in-garden.jpg?alt=media&token=4519f06c-3455-4842-9ac6-89abb039e6dd)`;
    return (
        <div>
            <Box
                sx={{
                    backgroundImage: `url(${Background})`,
                    width: '100%',
                    minHeight: {
                        xs: '100px',
                        lg: '400px'
                    },
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'                    
                }}
            >
                <Container>
                    <BookingSearchBar />
                </Container>
            </Box>
            <BookingDetail />
        </div>
    )
};
