import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useListings } from 'hooks/useListings';
import React, { useEffect } from 'react';
import { useCalendarReservations } from "hooks/useCalendarReservations";

import * as Yup from 'yup';

export const FormCalendar = () => {

    const { getListingsByUserUid, listings } = useListings(); 

    const { getCalendarReservations } = useCalendarReservations();

    useEffect(() => {
      
        getListingsByUserUid();
 
    }, []);
    

    const SearchPaysSchema = Yup.object().shape({
        listingId: Yup.string().required('Field Requiered'),
        startDate: Yup.date().required('Date required'),
        endDate:Yup.date().required('Date required'),
    });

    const formik = useFormik({
        initialValues:{
            listingId:'',
            startDate: new Date(),
            endDate:new Date()
        },
        onSubmit: async ( values ) => {

            getCalendarReservations({ ...values })
        },
        validationSchema:SearchPaysSchema
    });

  return (
      <>
          <form
              onSubmit={formik.handleSubmit}
              style={{
                  marginBottom: '1rem'
              }}
          >
              <FormControl fullWidth>
                  <Select
                       value={ formik.values.listingId }
                      label="Listings"
                      name="listingId"
                      displayEmpty
                      defaultValue={''}
                      onChange={formik.handleChange}
                  >
                     <MenuItem key={''} value={''}>
                                  Select option
                    </MenuItem>
                      {listings &&
                          listings.map((element, idx) => (
                              <MenuItem key={element.id + idx} value={element.id}>
                                  {element.name}
                              </MenuItem>
                          ))}
                  </Select>
                  { formik.errors.listingId }
              </FormControl>
              
              <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                          onChange={(value) => formik.setFieldValue('startDate', value, true)}
                          value={formik.values.startDate}
                          label="start date"
                          renderInput={(params) => <TextField margin="normal" name="startDate" fullWidth {...params} />}
                      />
                  </LocalizationProvider>
                  {/* { formik.errors.startDate } */}
              </FormControl>

              <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                          onChange={(value) => formik.setFieldValue('endDate', value, true)}
                          value={formik.values.endDate}
                          label="end date"
                          renderInput={(params) => <TextField margin="normal" name="endDate" fullWidth {...params} />}
                      />
                  </LocalizationProvider>
                  {/* { formik.errors.endDate } */}
              </FormControl>

              <Button
                fullWidth
                color="primary"
                variant="contained"
                size='large'    
                type="submit"    
              >
                Search
              </Button>
          </form>
      </>
  );
}
