import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import firebase from '../config/fbConfig';

export const useCalendarReservations = () => {
    
    const firestore = firebase.firestore();

    const [reservations, setReservations] = useState([]);

    const dispatch = useDispatch();

    const getCalendarReservations = async ( {listingId = '', startDate = '', endDate = ''} = {}) => {
        
        startDate = moment(startDate).startOf('day').toDate();
        endDate = moment(endDate).endOf('day').toDate();

        const data = await firestore
            .collection('booking-pays')
            .where('listingId', '==', listingId)
            .where('checkInDate', '>=', startDate)
            .where('checkInDate', '<=', endDate)
            .get().then((x) => { 
                return x.docs.map((doc) => { return {...doc.data(), id:doc.id} }) 
            });          
        // setReservations(data || []);

        const action = {
            type:'[Bookings Pay] Get Pays',
            payload:data || []
        }

        dispatch(action)
    };
    

    return {
        reservations,
        getCalendarReservations
    };
};
