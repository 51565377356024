import React, { useState } from 'react'
import { Button, Drawer, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import LanguageSelector from '../LanguageSection';

export const MenuResponsive = () => {

    const [state, setState] = useState(false);

    const toggleDrawer = (state = true) => {
        setState(state);
    };

    const styleTypographgy = {
        textAlign: 'center',
        margin:10
    };

    return (
        <>
            <IconButton
                aria-label="menu"
                color="inherit"
                onClick={() => toggleDrawer()}
                style={{ color: 'white' }}
            >
                <MenuIcon sx={{ fontSize:35 }}/>
            </IconButton>
            <Drawer
                anchor='top'
                open={state}
                onClose={() => toggleDrawer(false)}
                >
                <List component='nav' style={{ backgroundColor: '#FBB800' }}>
                    <ListItem button>
                        <ListItemText
                            style={ styleTypographgy }
                            primary={
                                <Typography color='white' variant='h4'>
                                     Hospital centers
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem button>
                        <ListItemText
                            style={ styleTypographgy }
                            primary={
                                <Typography color='white' variant='h4'>
                                     Recovery
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem button>
                        <ListItemText
                            style={ styleTypographgy }
                            primary={
                                <>
                                    <LanguageSelector />
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem button>
                        <ListItemText
                            style={ styleTypographgy }
                            primary={
                                <>
                                    <Link to={`/register`} style={{ textDecoration:'none' }}>
                                        <Typography color='white' variant='h4'>
                                            Sign up
                                        </Typography>
                                    </Link>
                                </>
                            }
                        />
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
}
