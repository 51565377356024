// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const sale = {
    id: 'venta',
    title: 'navigation.for_sale',
    type: 'group',
    children: [
        {
            id: 'venta',
            title: 'navigation.for_sale',
            type: 'item',
            url: '/venta',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default sale;
