import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import { cardDetail } from './booking-data'

export const BookingDetail = () => {
    return (
        <>
            <Box marginTop={3}>
                <Container fixed>
                    <Grid container spacing={2} marginBottom={3}>
                        {
                            cardDetail.map((element, i) => (
                                <Grid item lg={3} key={element.title}>
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={element.imgUrl}
                                            alt={element.text}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {element.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {
                                                    element.body
                                                }
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small">Share</Button>
                                            <Button size="small">Learn More</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Container>
            </Box>
        </>
    )
}
