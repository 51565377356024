export class SubListingModel {
    constructor(
        guests,
        address,
        name,
        updatedDate,
        createdBy,
        price,
        generalPrice,
        type_place,
        type,
        createdDate,
        images,
        listingId,
        updatedBy
    ) {
        this.guests = guests;
        this.address = address;
        this.name = name;
        this.updatedDate = updatedDate;
        this.createdBy = createdBy;
        this.price = price;
        this.generalPrice = generalPrice;
        this.type_place = type_place;
        this.type = type;
        this.createdDate = createdDate;
        this.images = images;
        this.listingId = listingId;
        this.updatedBy = updatedBy;
    }
}