import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { useBookings } from "hooks/useBookings";
import { useLayoutEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { checkInAvailableRooms, getGuestsTypes } from "store/actions";
import { catalogs } from "views/sub-listing/hooks/catalogs";

import Swal from 'sweetalert2';
import { useFirebase } from "hooks/useTypes";
import { useAvailability } from "hooks/useAvailability";
import { useParams } from "react-router";
import { getRoomByGuestTypeAndListingId } from "services/firebaseRecoveryService";

const DatePickerField = ({ name, value, onChange }) => {
    return (
        <DatePicker
            selected={(value && new Date(value)) || null}
            onChange={val => {
                onChange(name, val);
            }}
        />
    );
};

const DetailFormChecking = (props) => {
       
    const { listingId } = useParams();

    const { bookings } = useBookings();
    
    const { fbData } = useFirebase('typeRooms');

    const dispatch = useDispatch();

    useLayoutEffect(() => {
        if(bookings?.data){
            
            const { booking:bookingData } = bookings?.data;
            const { checkInDate, checkOutDate, type, totalCashDays } = bookingData;
            
            formik.setValues({checkInDate, checkOutDate, type})
        }
        
    }, [bookings]);

    const { guestsTypes } = catalogs(props);  
    
    const guests = guestsTypes();

    const { getFirebase } = useAvailability(listingId);// aquimequede

    const formik = useFormik({
        initialValues:{
            type:'',
            checkInDate: new Date(),
            checkOutDate: new Date(),
        },
        onSubmit: async ( values ) => {
            const { booking:bookingData } = bookings?.data;
            const { listingId } = bookingData;
            const { type, checkInDate, checkOutDate } = values;

            const rooms = await getRoomByGuestTypeAndListingId({ listingId,  guestType:type });
            
            if (rooms.length > 0) {
                
                props.checkInAvailableRooms({ listingId, checkInDate, checkOutDate, room:rooms[0] })

            } else {
                Swal.fire({
                    title: 'Rooms not availables',
                    text: 'Try with other room',
                    icon: 'info',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    dispatch({
                        type:'[Bookings] Reserve Booking',
                        payload:[]
                    });
                    
                });
            }       
        }
    });



    return (
        <div>
            <form onSubmit={ formik.handleSubmit }>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            onChange={( value) => formik.setFieldValue("checkInDate", value, true) }
                            value={ formik.values.checkInDate }
                            label="check in date"
                            renderInput={(params) => (
                                <TextField
                                    // error={Boolean(touched.checkInDate && errors.checkInDate)}
                                    // helperText={touched.checkInDate && errors.checkInDate}
                                    margin="normal"
                                    name="checkInDate"
                                    fullWidth
                                    {...params}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </FormControl>

                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            onChange={( value) => formik.setFieldValue("checkOutDate", value, true) }
                            value={ formik.values.checkOutDate }
                            label="check out date"
                            renderInput={(params) => (
                                <TextField
                                    // error={Boolean(touched.checkInDate && errors.checkInDate)}
                                    // helperText={touched.checkInDate && errors.checkInDate}
                                    margin="normal"
                                    name="checkOutDate"
                                    fullWidth
                                    {...params}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </FormControl>

                <FormControl
                    fullWidth
                    style={{ marginTop: '1rem' }}
                >
                    <InputLabel id="demo-simple-select-label">Guests</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ formik.values.type }
                        label="type"
                        name="type"
                        defaultValue={''}
                        onChange={ formik.handleChange }
                    >
                        {
                            guests &&
                            guests.map((element) => (
                                <MenuItem
                                    key={ element.type }
                                    value={element.type }
                                >
                                    {element.type}
                                </MenuItem>
                            ))
                        }
                    </Select>

                </FormControl>
                <Button
                    size="medium"
                    color='info'
                    style={{ marginTop: 15, marginRight: 10 }}
                    variant='outlined'
                    type='submit'
                >
                    Re-reserve
                </Button>
            </form>
        </div>
         
    )
}

const mapDispatchToProps = (dispatch) => ({
    checkInAvailableRooms: (data) => dispatch(checkInAvailableRooms(data)),
    getGuestsTypes: () => dispatch(getGuestsTypes())
});

const mapStateToProps = (state) => {
    const creator = state.firestore.data.creator ? state.firestore.data.creator : null;
    const filters = state.firestore.data.filters ? state.firestore.ordered.filters : null;
    return {
        creator,
        filters,
        auth: state.firebase.auth,
        authError: state.auth.authError
    };
};

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => {
        const collection = [
          
        ];
        return collection;
    })
)(DetailFormChecking);
