import { useState, useRef, useEffect } from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { useSelector, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
    Hidden
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import User1 from 'assets/images/users/user-round.svg';
import { signOut } from '../../../../store/actions';

// assets
import { IconLogout, IconSettings, IconCalendarTime, IconHome2 } from '@tabler/icons';
import { AdminSection } from './AdminSection';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = (props) => {
    const { auth, userData } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    if (auth.isEmpty) {
        return (
            <>
                <Hidden lgDown>
                    <Button
                        variant="text"
                        color="secondary"
                        style={{ color: 'white', fontWeight: 'bold' }}
                        onClick={(event) => handleListItemClick(event, 0, '/register')}
                    >
                        {t('navigation.register')}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ color: 'white', border: '2px solid white', fontWeight: 'bold' }}
                        onClick={(event) => handleListItemClick(event, 0, '/login')}
                    >
                        {t('navigation.login')}
                    </Button>
                </Hidden>
            </>
        );
    }
    return (
        <>
            <AdminSection />
        </>
    );
};

const mapStateToProps = (state) => {
    const userData = state.firestore.data.userData ? state.firestore.data.userData : null;
    return {
        userData,
        authError: state.auth.authError,
        auth: state.firebase.auth
    };
};

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(signOut())
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => [
        {
            collection: 'users',
            doc: props.auth.uid,
            storeAs: 'userData'
        }
    ])
)(ProfileSection);
