import { Button, Card, CardActions, CardContent, CardMedia, Chip, FormControl, Grid, TextField, Typography } from "@mui/material"
import { Box, Container } from "@mui/system"
import { useEffect } from "react";
import { useSelector } from "react-redux"

import { useTheme } from '@mui/material/styles';
import PayPal from "views/Pays/PayPal/PayPal";
import { useParams } from "react-router";
import DetailFormChecking from "./DetailFormChecking";
import moment from "moment";

export const ChekingMain = () => {

  const { selectedRoom } = useSelector((state) => state.bookings || {});

  const { listingId, subListingId } = useParams();

  useEffect(() => {
    if (!selectedRoom) {
        window.location.pathname = '/';
    }
  }, [selectedRoom])
  
  const theme = useTheme();

  return (
    <>
      <Container style={{ marginTop: '2rem' }} >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography variant="h3">
                  How do you want to pay?
                </Typography>
                <div style={{ marginTop: '1rem' }} ></div>
                <Box >
                  <PayPal />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={8}>
            <Card >
              <CardMedia
                component="img"
                height="300"
                image={ selectedRoom?.booking?.images[0] || ''}
              />
              <CardContent>
                <Typography gutterBottom variant="h2" component="div" marginBottom={2} >
                  { selectedRoom?.booking?.name }
                </Typography>

                <hr style={{ marginBottom:20 }} />
                
                <Chip label={ selectedRoom?.booking?.guests }  style={{ marginRight:5 }} color="primary" />
                <Chip label={ selectedRoom?.booking?.type_place } style={{ marginRight:5 }} color="primary" />
                <Chip label={ selectedRoom?.booking?.security } style={{ marginRight:5 }} color="primary" />

                <Typography variant="h4" color="black" marginTop={2}>
                  Your price summary
                </Typography>
                
                <Typography variant="h4" color={'gray'} marginTop={1.5}>
                  From: { moment(selectedRoom?.booking?.checkInDate).format('DD-MM-YYYY')}
                </Typography>

                <Typography variant="h4" color={'gray'} marginTop={1}>
                  To: ${ moment(selectedRoom?.booking?.checkOutDate).format('DD-MM-YYYY')}
                </Typography>
                
                <p>
                ${ selectedRoom?.booking.generalPrice } x { selectedRoom?.bookingDays.length } nights ${ selectedRoom?.booking.totalCashDays }
                </p>
                <hr />

                Total (USD) ${ selectedRoom?.booking.totalCashDays }

              </CardContent>

            </Card>
            <Card sx={{ marginTop: 2 }}>
              
            </Card>

          </Grid>
        </Grid>
      </Container>
    </>
  )
}
