import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import firebase from '../config/fbConfig';

export const useAvailability = (listingId = '') => {
    
    const firestore = firebase.firestore();
    
    useFirestoreConnect([
        {
            collection: 'sub-listings',
            storeAs: 'subListingsByListingId',
            where: ['listingId', '==', listingId ]
        }
    ]);

    const data = useSelector((state) => state.firestore.data.subListingsByListingId);

    const getFirebase = (typeOfRoom = '') => {
        
        if (data) {            
            const newData = Object.keys(data).map((key, i) => {
                return {
                    uid: key,
                    type: data[key].guests,
                    name:data[key].name,
                    generalPrice:data[key].generalPrice
                }
            })
           
            const filterData = newData
            .filter(
                    (element, idx) => {
                        let reg = new RegExp(typeOfRoom, 'gi')
                        if(element.type.match(reg)){
                            return element;
                        }
                    }
                )

            return filterData;          
        }
        return [];
    };

    const checkAvailabilityByDate = async ({ checkInDate, checkOutDate, listingId, roomId }) => {
                
        const data = await firestore
        .collection('booking-days')                    
        .where('listingId', '==', listingId)
        .where('roomId', '==', roomId)
        .where('dateReserved', '>=', checkInDate)
        .where('dateReserved', '<=', checkOutDate)
        .get()
        .then(x => {
            const res = x.docs.map((doc) => doc.data())
            return res;
        });
        
        if (data.length === 0) {
            return true;
        }

        return false;
    }

    return {
        getFirebase,
        checkAvailabilityByDate,
        checkAvailabilityByDate
    }

}
