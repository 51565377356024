export class BookingDay{
    constructor(
        bookingPayId,
        createdBy,
        createdDate,
        dateReserved,
        generalPrice,
        listingId,
        roomId,
        status,
        isActive
    ){
        this.bookingPayId = bookingPayId;
        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.dateReserved = dateReserved;
        this.generalPrice = generalPrice;
        this.listingId = listingId;
        this.roomId = roomId;
        this.status = status;
        this.isActive = true;
    }
}