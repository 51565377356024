import React from 'react';

// material-ui
import { Typography, Checkbox, Box, FormControlLabel, Container, Grid, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme } from '@mui/system';
import LogoSection from '../LogoSection';

const Footer = () => {
    const { i18n } = useTranslation();
    const theme = useTheme();

    const footerText = [
        {
            label: 'About us',
            options: [
                { label: 'How does this work?', link: '/' },
                { label: 'News', link: '/' },
                { label: 'Investors', link: '/' }
            ]
        },
        {
            label: 'Community',
            options: [
                { label: 'Diversity', link: '/' },
                { label: 'Accessibility', link: '/' },
                { label: 'Partners', link: '/' },
                { label: 'Guests', link: '/' }
            ]
        },
        {
            label: 'Host',
            options: [
                { label: 'Host in your home', link: '/' },
                { label: 'Offer an experience', link: '/' },
                { label: 'Be a responsible host', link: '/' },
                { label: 'Community center', link: '/' }
            ]
        },
        {
            label: 'Support',
            options: [
                { label: 'Our response to COVID-19', link: '/' },
                { label: 'Help Center', link: '/' },
                { label: 'Cancellation Options', link: '/' },
                { label: 'Trust and security', link: '/' }
            ]
        }
    ];

    return (
        <Box sx={{ backgroundColor: theme.palette.secondary.main, color: 'white', padding: theme.spacing(2) }}>
            <Container>
                <LogoSection />
                <Grid container paddingTop={theme.spacing(1)}>
                    {footerText.map((section, index) => (
                        <Grid item xs={12} md={3} id={index} key={index}>
                            <Typography variant="h3" sx={{ color: 'white' }}>
                                {section.label}
                            </Typography>
                            <List>
                                {section.options.map((subsection, index) => (
                                    <ListItem disablePadding key={subsection.label}>
                                        <ListItemButton sx={{ color: 'white' }}>
                                            <ListItemText primary={subsection.label} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;
