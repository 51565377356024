import { Stack, Chip } from '@mui/material';

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import TranslateIcon from '@mui/icons-material/Translate';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import MapIcon from '@mui/icons-material/Map';

export default function MakeHighlights({ selectedHighlights }) {
    if (!selectedHighlights) return null;
    const highlights = {
        nurses: {
            label: '24hrs Nurses',
            icon: <AccessAlarmIcon fontSize="small" />,
            color: 'LightSalmon'
        },
        certified_staff: {
            label: 'Certified Medical Staff',
            icon: <MedicalServicesIcon fontSize="small" />,
            color: 'DarkTurquoise'
        },
        english_speaker: {
            label: '100% English Speakers',
            icon: <TranslateIcon fontSize="small" />,
            color: 'Teal'
        },
        transportation: {
            label: 'Transportation included',
            icon: <DriveEtaIcon fontSize="small" />,
            color: 'DarkSeaGreen'
        },
        friendly_staff: {
            label: 'Friendly Staff',
            icon: <InsertEmoticonIcon fontSize="small" />,
            color: 'GoldenRod'
        },
        close_border: { label: 'Close to the border', icon: <MapIcon fontSize="small" />, color: 'blue' }
    };

    return (
        <div>
            {selectedHighlights.map((highlight, index) => (
                <Chip
                    key={highlight}
                    icon={highlights[highlight].icon}
                    label={highlights[highlight].label}
                    size='small'
                    sx={{
                        marginTop: 1
                    }}
                />
            ))}
        </div>
    );
}
