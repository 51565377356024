
const bookingReducer = (initialState = null, action) => {
    switch (action.type) {
        case '[Bookings] Reserve Booking':            
            return { ...initialState, data: action.payload};
        case '[Available Rooms] Available':            
            return { ...initialState, availableRooms: action.payload};
        case '[Bookings] Selected Room':            
            return { ...initialState, selectedRoom: action.payload};
        case '[Bookings] Selected form checking':            
            return { ...initialState, selectedBookingForm: action.payload};
        case '[Bookings Pay] Get Pays':            
            return { ...initialState, paysByListing: action.payload};
        default:
            return initialState;
    }
};

export default bookingReducer;
