import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getDaysByTwoDates } from "store/actions";
import firebase from '../config/fbConfig';

export const useBookings = () => {
    
    const firestore = firebase.firestore();
    const { bookings } = useSelector((state) => state );

    useEffect(() => {
      
    }, [bookings]);

    const getDayBooked = ({ checkInDate, checkOutDate, generalPrice, listingId, roomId, 
    }) => {
        
        const days = getDaysByTwoDates(checkInDate, checkOutDate);
        let totalCashDays = 0;
        let bookingDays = [];
        const createdBy = firebase.auth().currentUser.uid;
        const createdDate = new Date();

        days.forEach((element) => {
            totalCashDays = generalPrice + totalCashDays;

            bookingDays = [
                ...bookingDays,
                {
                    date: element,
                    listingId,
                    roomId,
                    createdBy,
                    createdDate,
                    generalPrice
                }
            ];
        });

        return {bookingDays, totalCashDays};

    };

    return {
        bookings,
        getDayBooked
    }
}
