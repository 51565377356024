import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { useListings } from 'hooks/useListings';
import { useSubListings } from 'hooks/useSubListings';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { useBookingStore } from "hooks/useBookingStore";
import { useBookings } from "hooks/useBookings";
import { useAvailability } from "hooks/useAvailability";
import { BookingPay } from "models/bookingpay.model";
import { BookingPayStatus } from "enum/bookingpaystatus.enum";
import { useBookingPay } from "hooks/useBookingPay";
import { useBookingDays } from "hooks/useBookingDays";
import moment from "moment";
import { useAuthInformation } from "hooks/useAuthInformation";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

export const ManualModalReservation = ({ open, setOpen }) => {

    const { setSelectedRoom, selectedDaysCount, getDaysFromFormBooking } = useBookingStore();

    const { getSublisting } = useSubListings();

    const { getListing  } = useListings();

    const { getDayBooked } = useBookings();

    const { checkAvailabilityByDate } = useAvailability();

    const { setBookingPay } = useBookingPay();

    const { setBookingDays } = useBookingDays();

    const { auth } = useAuthInformation();

    const reserve = (room) => {
        setSelectedRoom( room )
    }

  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues:{
        listingId:'',
        subListingId:'',
        checkInDate: new Date(),
        checkOutDate: new Date(),
        firsName:'',
        lastName:''
    },
    onSubmit: async ({ listingId,
                subListingId,
                checkInDate,
                checkOutDate,
                firsName,
                lastName }) => {
        
        checkInDate = moment(checkInDate).startOf('day').toDate();
        checkOutDate = moment(checkOutDate).endOf('day').toDate();

        //Paso 1 traer informacion de sublisting
        const subListing = await getSublisting( subListingId );

        //Paso 2 Traer informacion de listing
        const listing = await getListing( subListing?.listingId );

        //Paso 2.2 Hacer el conteo de dias para insertarlos
        const daysFromBooked = getDayBooked({checkInDate, checkOutDate, generalPrice:subListing?.generalPrice});

        //Paso 2.3 Validacion de dias 
        const checkDays = await checkAvailabilityByDate({ checkInDate, checkOutDate, listingId:subListing.listingId, roomId:subListingId});
        
        if(!checkDays){

            alert('no days availables');

            return;
        }

        let bookingpay = new BookingPay();

        //Paso 4 Reservar por typo manual y hacer match con campos de paypal
        bookingpay = {
            address:{},
            checkInDate,
            checkOutDate,
            create_time:new Date(),
            createdBy:auth.uid,
            createdDate:new Date(),
            email_address:'',
            id:'',
            listingId:subListing.listingId,
            name:{
                given_name:lastName,
                surname:firsName
            },
            days:daysFromBooked.bookingDays.length,
            payer_id:'',
            roomId:subListingId,
            status:BookingPayStatus.COMPLETED,
            isActive:true,
            totalCashDays:daysFromBooked.totalCashDays,
            assignedTo:''
        };
        
        //Paso 5 Reservar
        const bookingPayId = await setBookingPay(bookingpay);
    
        //Paso 6 Insertar dias a collecion booking days

        setBookingDays({...daysFromBooked, bookingPayId, subListingId, listingId, generalPrice:subListing.generalPrice });

    }
  })

  const { getListingsByUserUid, listings } = useListings();

  const { subListings, getSublistings } = useSubListings();

  useEffect(() => {
      getListingsByUserUid();
  }, []);
    
  const columns = [
    {
      name: "cedula",
      label: "Room",
    },
    {
      name: "firstName",
      label: "Guests",
    },
    {
      name: "lastName",
      label: "Type",
    },
    {
      name: "status",
      label: "Status",
    },
  ];

  return (
      <Modal
          open={open}
          onClose={(_, reason) => {
              if (reason !== 'backdropClick') {
                  handleClose();
              }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <Box sx={style}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                  Add new entry
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={1}>
                      <Grid item sm={6}>
                          <FormControl fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                      onChange={(value) => formik.setFieldValue('checkInDate', value, true)}
                                      value={formik.values.checkInDate}
                                      label="check in date"
                                      renderInput={(params) => <TextField margin="normal" name="checkInDate" fullWidth {...params} />}
                                  />
                              </LocalizationProvider>
                          </FormControl>
                      </Grid>
                      <Grid item sm={6}>
                          <FormControl fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                      onChange={(value) => formik.setFieldValue('checkOutDate', value, true)}
                                      value={formik.values.checkOutDate}
                                      label="check out date"
                                      renderInput={(params) => <TextField margin="normal" name="checkOutDate" fullWidth {...params} />}
                                  />
                              </LocalizationProvider>
                          </FormControl>
                      </Grid>
                      <Grid item sm={6}>
                          <Select
                              style={{
                                  marginTop: '1rem'
                              }}
                              fullWidth
                              value={formik.values.listingId}
                              label="Listings"
                              name="listingId"
                              displayEmpty
                              defaultValue={''}
                              onChange={(e) => {
                                  formik.handleChange(e);
                                  const { target } = e;
                                  if (target.value !== '') {
                                      getSublistings(target.value);
                                  } else {
                                      getSublistings('');
                                  }
                              }}
                          >
                              <MenuItem key={''} value={''}>
                                  Select option
                              </MenuItem>
                              {listings &&
                                  listings.map((element, idx) => (
                                      <MenuItem key={element.id + idx} value={element.id}>
                                          {element.name}
                                      </MenuItem>
                                  ))}
                          </Select>
                      </Grid>
                      <Grid item sm={6}>
                          <Select
                              style={{
                                  marginTop: '1rem'
                              }}
                              fullWidth
                              value={formik.values.subListingId}
                              label="Room"
                              name="subListingId"
                              displayEmpty
                              disabled={subListings.length === 0}
                              defaultValue={''}
                              onChange={formik.handleChange}
                          >
                              <MenuItem key={''} value={''}>
                                  Select option
                              </MenuItem>
                              {subListings &&
                                  subListings.map((element, idx) => (
                                      <MenuItem key={element.id + idx} value={element.id}>
                                          {element.name}
                                      </MenuItem>
                                  ))}
                          </Select>
                      </Grid>

                      <Grid item sm={6}>
                          <TextField
                              style={{
                                  marginTop: '1rem'
                              }}
                              fullWidth
                              //   onChange={ formik.handleChange }
                              onChange={({ target }) => formik.setFieldValue('firsName', target.value, true)}
                              value={formik.values.firsName}
                              id="outlined-basic"
                              label="First name"
                              variant="outlined"
                          />
                      </Grid>

                      <Grid item sm={6}>
                          <TextField
                              style={{
                                  marginTop: '1rem'
                              }}
                              fullWidth
                            //   onChange={formik.handleChange}
                            onChange={({ target }) => formik.setFieldValue('lastName', target.value, true)}
                              value={formik.values.lastName}
                              id="outlined-basic"
                              label="Last name"
                              variant="outlined"
                          />
                      </Grid>
                  </Grid>

                  <FormControl fullWidth>
                      <Button
                          style={{
                              marginTop: '1rem'
                          }}
                          variant="contained"
                          size="medium"
                          type="submit"
                      >
                          Save
                      </Button>
                  </FormControl>

                  <FormControl fullWidth style={{ marginTop: '1rem' }}>
                      <Button
                          type="button"
                          color="error"
                          variant="contained"
                          onClick={() => {
                              handleClose();
                              formik.resetForm();
                              getSublistings('');
                          }}
                      >
                          Cancel
                      </Button>
                  </FormControl>
              </form>
              {/* <div style={{ marginTop: '1rem' }}>
                  <MUIDataTable title={'Doctors list'} data={[]} columns={columns} />
              </div> */}
          </Box>
      </Modal>
  );
}
