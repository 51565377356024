import { useBookings } from "hooks/useBookings";
import { useEffect, useLayoutEffect, useRef } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { recoveryRoomIsAvailable } from "services/firebaseRecoveryService";
import { setCheckingDates } from "store/actions";

import Swal from 'sweetalert2';

const PayPal = (props) => {
   
    const { bookings } = useBookings();

    const paypal = useRef();

    useEffect(() => {

        window.paypal.Buttons({
            createOrder: async (data, actions, err) => {
                if( bookings ){
                    const { selectedRoom } = bookings;
                    const { listingId, checkInDate, checkOutDate, roomId } = selectedRoom.booking;
                    
                    const isAvailableRoom = await recoveryRoomIsAvailable({listingId, checkInDate, checkOutDate, roomId});
                    
                    if(isAvailableRoom){
                        return actions.order.create({
                            intent:"CAPTURE",
                            purchase_units:[
                                {
                                    description:`${bookings?.selectedRoom?.booking?.name}`,
                                    amount:{
                                        value:bookings?.selectedRoom?.booking?.totalCashDays,
                                        currency_code:"USD"
                                    }
                                }
                            ]
                        })
                    }else{
                        throw ('Rooms not available... please try with other dates.')
                    }
                }else{

                }
            },
            onApprove: async (data, actions) => {

                const order  = await actions.order.capture();
                   
                props.setCheckingDates(order);
         
            },
            onError: (err) => {
                console.log( err );
                Swal.fire({
                    title: 'Rooms validation',
                    text: `${err}`,
                    icon: 'info',
                    confirmButtonText: 'Ok'
                }).then(() => {

                });
            }
        }).render(paypal.current)

    }, []);

    return (
        <div>
            <div ref={paypal}>

            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setCheckingDates: (data) => dispatch( setCheckingDates(data) )
});

const mapStateToProps = (state) => {
    const creator = state.firestore.data.creator ? state.firestore.data.creator : null;
    const filters = state.firestore.data.filters ? state.firestore.ordered.filters : null;
    return {
        creator,
        filters,
        auth: state.firebase.auth,
        authError: state.auth.authError
    };
};

export default compose (
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => {
        const collection = [
          
        ];
        return collection;
    })
)(PayPal);