import { BookingPayStatus } from "enum/bookingpaystatus.enum";
import { BookingDay } from "models/bookingday.model";
import firebase from '../config/fbConfig';

export const useBookingDays = () => {
    
    const firestore = firebase.firestore();

    const setBookingDays = async ({
        bookingDays = [],
        totalCashDays = 0,
        bookingPayId,
        subListingId,
        listingId,
        generalPrice
    }) => {
        
        bookingDays.forEach(async ( { createdBy, createdDate, date, generalPrice }, idx) => {
            
            let bookingDay = new BookingDay();
            
            bookingDay = {
                bookingPayId,
                createdBy,
                createdDate : new Date(),
                dateReserved : date,
                generalPrice,
                listingId,
                roomId:subListingId,
                status:BookingPayStatus.RESERVED
            }
            
            await firestore
                .collection('booking-days')
                .add(bookingDay)
                .then((docRef) => {
                    return docRef.id;
                });
        });

    };

    return {
        setBookingDays
    };
}
