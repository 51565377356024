import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        {/* <Logo /> */}
        <img
            src="https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2FGroup%205886.png?alt=media&token=042ae47a-a312-4f1e-b515-85db17d812a0"
            alt="Logo"
           style={{
            maxWidth: '100%',
            height: 'auto',
            width:'250px',
            padding: 0,
            margin: 0
           }}
        />
    </ButtonBase>
);

export default LogoSection;
