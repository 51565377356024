export class BookingPay {
    constructor(
        address,
        checkInDate,
        checkOutDate,
        create_time,
        createdBy,
        createdDate,
        email_address,
        id,
        listingId,
        name,
        payer_id,
        status,
        statusType,
        isActive,
        assignedTo,
        totalCashDays
    ) {
        this.address = address;
        this.checkInDate = checkInDate;
        this.checkOutDate = checkOutDate;
        this.create_time = create_time;
        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.email_address = email_address;
        this.id = id;
        this.listingId = listingId;
        this.name = name;
        this.payer_id = payer_id;
        this.status = status;
        this.statusType = statusType;
        this.isActive = isActive;
        this.assignedTo = assignedTo;
        this.totalCashDays = totalCashDays;
    }
}