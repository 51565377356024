import { Typography } from '@mui/material'
import React, { useState } from 'react'

export const DescriptionPar = ({ text = "N/A", style = {} }) => {

    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {

        setIsReadMore(!isReadMore);

    };

    return (
        <div>
            <Typography style={style}>
                {
                    isReadMore ? text.slice(0, 150) : text
                }
            </Typography>
            <Typography variant='subtitle1' onClick={ toggleReadMore } style={{ cursor: 'pointer', ...style, color:'black'}}>
                {
                    isReadMore ? '...read more' : ' show less'
                }
            </Typography>
        </div>
    )
}


