import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

export const PrivateRoute = ({children}) => {

    const auth = useSelector((state) => state.firebase.auth);

    useEffect(() => {}, [auth])
    
    return auth.uid 
    ? 
        children 
    :  
        <Navigate to='/' />
}
