import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

// Replace this with your own config details
const config = {
    apiKey: 'AIzaSyBVvGxuZNa-OOKwL1IqJeWkJq6beUOah3g',
    authDomain: 'recovery-house.firebaseapp.com',
    databaseURL: 'https://recovery-house-default-rtdb.firebaseio.com',
    projectId: 'recovery-house',
    storageBucket: 'recovery-house.appspot.com',
    messagingSenderId: '392658133213',
    appId: '1:392658133213:web:3914015c4666d767b57b34',
    measurementId: 'G-TXP8SB2ZGE'
};
firebase.initializeApp(config);
firebase.firestore();
firebase.functions();
firebase.storage();

export default firebase;
