export const cardDetail = [
    {
        title:'Image 1',
        text:'sub text image 1',
        body:'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
        imgUrl:'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Fbookings%2Fblur-hospital.jpg?alt=media&token=410b20d4-644f-4ac0-870b-30d4429db11d'
    },
    {
        title:'Image 2',
        text:'sub text image 2',
        body:'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
        imgUrl:'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Fbookings%2Flonely-road-with-modern-buildings.jpg?alt=media&token=84fc8d11-39eb-44e1-b667-eeb5a425cd70'
    },
    {
        title:'Image 3',
        text:'sub text image 3',
        body:'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
        imgUrl:'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Fbookings%2Flonely-road-with-modern-buildings.jpg?alt=media&token=84fc8d11-39eb-44e1-b667-eeb5a425cd70'
    },
    {
        title:'Image 4',
        text:'sub text image 4',
        body:'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
        imgUrl:'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Fbookings%2Fblur-hospital.jpg?alt=media&token=410b20d4-644f-4ac0-870b-30d4429db11d'
    },
];

