import { Delete } from '@mui/icons-material';
import { Button, Card, CardContent, CardMedia, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { Field, Form, Formik } from 'formik';
import { useForm } from 'hooks/useForm';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { DropzoneArea } from 'react-mui-dropzone';
import { connect, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { NavLink, useSearchParams } from 'react-router-dom';
import { compose } from 'redux';
import { saveSubListing, editSubListingImg, getGuestsTypes } from 'store/actions';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useFirebase } from 'hooks/useTypes';
import { catalogs } from '../hooks/catalogs';

const SaveSubListing = (props) => {

  const theme = useTheme();
  const [img, setImages] = useState([])
  const [searchParams, setSearchParams] = useSearchParams();
  const listingId = searchParams.get('listingId');
  const subListingId = searchParams.get('sub-listingId');

  const handleDeleteImg = (element) => {

    const newData = subListing.images.filter((img) => {
      if (img !== element) {
        return element;
      }
    });
    props.editSubListingImg(subListingId, newData);

  }

  const handleChangeImages = (images) => {
    setImages({ images });
  };

  useFirestoreConnect([
    {
      collection: `sub-listings`,
      doc: subListingId,
      storeAs: 'subListing'
    }
  ]);

  const subListing = useSelector((state) => state.firestore.data.subListing);
  
  const {
    formState:formValues,
    onResetForm:reset,
    setFormState } = useForm(
      {
        id: '',
        name: '',
        price: '',
        generalPrice: '',
        type: '',
        images: [],
        guests: '',
      });

  useEffect(() => {

    if (subListingId !== 'new') {
      if (subListing) {

        setFormState(subListing);
      }
    }

    return () => {
      reset();
    }

  }, [subListing])

  const handleSubmit = (e) => {

    subListingId === 'new' ? props.saveSubListing({ ...formValues, id: '', listingId }, img) : props.saveSubListing({ ...formValues, id: subListingId, listingId }, img);

  };

  const urlBack = () => {
    let listingId = searchParams.get('listingId');
    let url = `/listing/sub-listings/${listingId}`;
    return url;
  };

  const { name, price, generalPrice, images, type } = formValues;
  
  const { fbData } = useFirebase('typeRooms');

  const { guestsTypes } = catalogs(props);
  
  const guests = guestsTypes();

  return (
    <>
      <Container style={{ marginTop: 15, marginBottom: 15 }}>
        <Grid container>
          <Grid item sm={12}>

            <Card>
              <CardContent>
                <Typography
                  style={{ marginBottom: '1rem' }}
                  component="div"
                  variant="h3"
                >
                  Sub listing form
                </Typography>

                <Formik
                  initialValues={formValues}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Name is required'),
                    price: Yup.number().required('Price is require'),
                    generalPrice: Yup.number().required('General price is require'),
                    type: Yup.string().required('Type is require'),
                    guests: Yup.string().required('Guests is require')
                  })}

                  onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    
                    if (subListingId === 'new') {

                      props.saveSubListing({ ...values, id: '', listingId }, img)

                    } else {

                      props.saveSubListing({ ...values, id: subListingId, listingId }, img);

                    }
                  }}
                >
                  {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <FormControl fullWidth>
                        <TextField
                          error={errors.name && touched.name}
                          name="name"
                          id='name'
                          label='Name room'
                          type='text'
                          fullWidth
                          helperText='Type a name of room'
                          value={values.name}
                          onChange={handleChange}
                          style={{ marginBottom: 15 }}
                        />

                        {/* <FormHelperText id="name">sdsdsd</FormHelperText> */}

                      </FormControl>

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Type of room</InputLabel>
                        <Field
                          error={errors.type && touched.type}
                          name="type"
                          id='type'
                          labelId="demo-simple-select-label"
                          value={values.type}
                          label="Type"
                          onChange={handleChange}
                          component={CustomizedSelectForFormik}>                          
                          {fbData.map((element) => (
                            <MenuItem
                              id={element.type}
                              name={element.type}
                              key={element.type}
                              value={element.type}
                            >
                              {element.type}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Guests</InputLabel>
                        <Field
                          error={errors.guests && touched.guests}
                          name="guests"
                          id='guests'
                          labelId="demo-simple-select-label"
                          value={values.guests}
                          label="Guests"
                          onChange={handleChange}
                          component={CustomizedSelectForFormik}>                          
                          { guests && guests.map((element) => (
                            <MenuItem
                              id={element.type}
                              name={element.type}
                              key={element.type}
                              value={element.type}
                            >
                              {element.type}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>

                      <FormControl fullWidth>
                        <TextField
                          error={errors.price && touched.price}
                          name="price"
                          id='price'
                          label='Price room'
                          type='number'
                          value={values.price}
                          helperText='Type a price room'
                          onChange={handleChange}
                          style={{ marginBottom: 15 }}
                        />
                      </FormControl>

                      <FormControl fullWidth>
                        <TextField
                          error={errors.generalPrice && touched.generalPrice}
                          name="generalPrice"
                          id='generalPrice'
                          label='General price room'
                          type='number'
                          helperText='Type a general price'
                          value={values.generalPrice}
                          onChange={handleChange}
                          style={{ marginBottom: 15 }}
                        />
                      </FormControl>

                      <DropzoneArea
                        dropzoneText="Drag and drop a document here or click"
                        acceptedFiles={['image/*']}
                        onChange={handleChangeImages}
                        filesLimit={5}
                      />
                      <Grid container spacing={2} marginTop={0}>
                        {
                          images &&
                          images.map((row, idx) =>
                          (
                            <Grid item xl={2} key={row}>
                              <Card >
                                <div style={{ position: "relative" }} >
                                  <CardMedia
                                    style={{ height: 100, paddingTop: "0%", cursor: 'pointer' }}
                                    component="img"
                                    image={row}
                                    alt="Pancakes" />
                                  <div style={{ position: "absolute", color: "white", fontWeight: "bold", top: 0, left: "90%", transform: "translateX(-50%)", }}>
                                    <Button
                                      variant='text'
                                      size='small'
                                      color='error'
                                      onClick={() => handleDeleteImg(row)}
                                    >
                                      <IconButton color="error" component="span">
                                        <Delete />
                                      </IconButton>

                                    </Button>
                                  </div>
                                </div>
                              </Card>
                            </Grid>
                          ))
                        }

                      </Grid>

                      <Button
                        color={
                          subListingId === 'new' ? 'success' : 'warning'
                        }
                        fullWidth
                        size="large"
                        style={{ marginTop: 15 }}
                        variant='outlined'
                        type='submit'
                      >
                        {
                          subListingId === 'new' ? (<div>Save</div>) : (<div>Update</div>)
                        }
                      </Button>
                      <Button
                        fullWidth
                        size="large"
                        color='info'
                        style={{ marginTop: 15 }}
                        variant='outlined'
                        type='button'
                        component={NavLink}
                        to={urlBack()}
                      >
                        Back
                      </Button>

                    </form>
                  )}
                </Formik>
              </CardContent>
            </Card>

          </Grid>
        </Grid>
      </Container>
    </>
  )
}

const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      name={name}
      value={value}
      onChange={e => {
        setFieldValue(name, e.target.value);
      }}
      style={{ marginBottom: 15 }}
    >
      {children}
    </Select>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveSubListing: (saveListing, images) => dispatch(saveSubListing(saveListing, images)),
  editSubListingImg: (saveListing, images) => dispatch(editSubListingImg(saveListing, images)),
  getGuestsTypes: () => dispatch(getGuestsTypes())
});

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SaveSubListing);
