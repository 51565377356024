import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './authReducer';
import bookingReducer from 'reducers/bookingReducer';
import guestsReducer from 'reducers/guestsReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    customization: customizationReducer,
    auth: authReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    bookings:bookingReducer,
    guests:guestsReducer
});

export default reducer;
