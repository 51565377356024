import React, { useState, useEffect, useRef } from "react";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["(cities)"] }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  
  updateQuery(query);
}

function SearchLocationInput({ setLocation, location = '' }) {
  
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyBXltw_T0i-aJYwAdqbXPZLIC-mfm280OA&libraries=places`,
      () => handleScriptLoad(setLocation, autoCompleteRef)
    );
    
  }, []);
  
  return (
      <div className="search-location-input">
          <input
              style={{ width: '100%', height: '3rem', padding: '.7rem' }}
              ref={autoCompleteRef}
              onChange={({target}) => {
                
                const term = target.value;
                
                // if(term.match(/,/ig)){
                //   const place = term.split(',')[0];
                //   setLocation(place);
                //   return;
                // }
              
                setLocation(term);

              }}
              onKeyDown={ (e) => {
                if(e.key === 'Enter'){
                  // debugger
                  // if(location.match(/,/ig)){
                  //   const place = location.split(',')[0];
                  //   setLocation(place);
                  //   return;
                  // }
                
                  setLocation(location);
                }
              }}
              placeholder="Enter a City"
              value={ location }
          />
      </div>
  );
}


export default SearchLocationInput;