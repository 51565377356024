import React from 'react';
import { Card, CardContent, Grid, Typography, Box, FormControl, OutlinedInput, TextField, Button, Chip } from '@mui/material';
import { Container } from '@mui/system';
import MapIcon from '@mui/icons-material/Map';
import { Link } from 'react-router-dom';
import { MasonryRecoveryDetailRoom } from './MasonryRecoveryDetailRoom';

export const BookingCheck = () => {
    return (
        <>
            <Container  maxWidth="xl">
                <Grid container spacing={2} marginTop={3}>
                    <Grid item lg={3}>
                        <Box>
                            <Card style={{ backgroundColor: '#FFFFFF' }}>
                                <CardContent>
                                    <Typography variant='h2'>Search</Typography>
                                    <Box marginTop={2}>
                                        <FormControl sx={{ width: '100%', marginBottom:2 }}>
                                            <TextField 
                                                label="Place" 
                                                variant="outlined" 
                                                size='small' 
                                                fullWidth />
                                        </FormControl>
                                        <FormControl sx={{ width: '100%', marginBottom:2 }}>
                                            <TextField 
                                                label="From" 
                                                variant="outlined" 
                                                size='small' 
                                                fullWidth />
                                        </FormControl>
                                        <FormControl sx={{ width: '100%', marginBottom:2 }}>
                                            <TextField 
                                                label="To" 
                                                variant="outlined" 
                                                size='small' 
                                                fullWidth />
                                        </FormControl>
                                        <FormControl sx={{ width: '100%', marginBottom:2 }}>
                                            <TextField 
                                                label="Type room" 
                                                variant="outlined" 
                                                size='small' 
                                                fullWidth />
                                        </FormControl>
                                        <Button 
                                            size='medium'
                                            fullWidth
                                            variant='contained'
                                            color='info'
                                            >
                                            Search
                                        </Button>
                                        <Box style={{ marginTop:"1rem" }}>
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.521260322283!2d106.8195613507864!3d-6.194741395493371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5390917b759%3A0x6b45e67356080477!2sPT%20Kulkul%20Teknologi%20Internasional!5e0!3m2!1sen!2sid!4v1601138221085!5m2!1sen!2sid"
                                                frameBorder="0"
                                                style={{ border: 0 }}
                                                allowFullScreen=""
                                                aria-hidden="false"
                                                tabIndex="0"
                                            />
                                        </Box>
                                    </Box> 
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item lg={9}>
                        <Box>
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center">
                                        <Grid item>
                                            <Chip label="Recovery house" />
                                            <Typography variant='h2' marginTop={1}>House premier TJ</Typography>

                                            <Typography variant='h4' marginTop={1} style={{ cursor:'pointer' }}>
                                                Tijuana B.C. Hipodromo Av. Real
                                            </Typography>
                                                
                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained'>
                                                Booking now!                                               
                                            </Button>
                                        </Grid>                                       
                                    </Grid>
                                    <MasonryRecoveryDetailRoom />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
