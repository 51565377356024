
import moment from 'moment';
import { getDaysByTwoDates } from 'store/actions';
import firebase from '../config/fbConfig';

const firestore = firebase.firestore();

export const getAvailablesRoom = async (props, dispatch) => {

    const { checkInDate, checkOutDate,location,guestType,placeType } = props;
    
    const roomsByFilter = await firestore.collection('sub-listings')

                                         .where('type_place','==',placeType ? placeType : '')                                          
                                         .where('guests','==',guestType ? guestType : '')
                                         .where('address.city','==',location)
                                         .get()
                                         .then(x => {
                                            const res = x.docs.map((doc) => ({id: doc.id, ...doc.data()}))
                                            return res;
                                        });
    let outputlist = [];

    const promises = [];
    
    roomsByFilter.forEach( (element) => {
        
        const { id, listingId } = element;

        let promise = firestore
                        .collection('booking-days')                    
                        .where('roomId', '==', id)
                        .where('dateReserved', '>=', moment(checkInDate).startOf('day').toDate())
                        .where('dateReserved', '<=', moment(checkOutDate).endOf('day').toDate())
                        .get()
                        .then(async x => {
                            const res = x.docs.map((doc) => doc.data())
                            
                            if(res.length === 0){
                                
                                let recovery = {};

                                let data = await firestore.collection('listing_info').doc(listingId).get().then((docRef) => docRef.data() );
                                    
                                element = { ...element, recovery:data }

                                outputlist.push(element);

                            }

                            return;``
                            
                        })
                        .catch(err => {
                            console.log(err);
                        });

            promises.push(promise);
                                
    });
    
    Promise.all(promises).then(() => {

        const action = {
            type:'[Available Rooms] Available',
            payload:[
                ...outputlist
            ]
        }

        dispatch(action);
        
    })
    
}

export const setSelectedRoom = ({}, dispatch) => {

    const days = getDaysByTwoDates(checkInDate, checkOutDate);
                    
                    days.forEach((element) => {
                        totalCashDays =  generalPrice + totalCashDays;

                        bookingDays = [
                            ...bookingDays,
                            {
                                date: element,
                                listingId,
                                roomId: uid,
                                createdBy,
                                createdDate,
                                room
                            }
                        ];
                    });
                    const action = {
                        type:'[Bookings] Selected Room',
                        payload:{
                            bookingDays,
                            booking:{
                                listingId, checkInDate, checkOutDate, ...room,
                                totalCashDays
                            }
                        }
                    }

                    dispatch(action);
                    
                    return;
}