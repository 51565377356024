import { ListingModel } from 'models/listing.model';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import firebase from '../config/fbConfig';

export const useListings = () => {
    const firestore = firebase.firestore();
    
    const [listings, setListings] = useState([]);

    const auth = useSelector((state) => state.firebase.auth);

    const getListingsByUserUid = async () => {
        const { uid } = auth; 
        
        if( uid ){

            const data = await firestore.collection('listing_info')
                                        .where('creator', '==', uid)
                                        .get().then((x) => { 
                                            return x.docs.map((doc) => { return {...doc.data(), id:doc.id} }) 
                                        });

            setListings( data );
        }

    };

    const getListing = async(id) => {
        if ( id === '' ) return;
        
        const data = await firestore
            .collection('listing_info')
            .doc(id)
            .get()
            .then((snapshot) => {
                return snapshot.data();
            })
            .catch((e) => console.log(e));
            
            let listing = new ListingModel();

            listing = { ...data };

            return listing;
    };

    return {
        getListingsByUserUid,
        listings,
        getListing
    };
};

// 0112576347 numero de cuenta totalplay, 
// orden de servicio: 23018523