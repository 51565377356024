// import { DatePicker, LocalizationProvider } from '@mui/lab';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { getGuestTypesCatalog } from 'services/firebaseRecoveryService';
import { getRecoveryTypes } from 'services/ recoverytypes';
import { getAvailablesRoom } from 'services/recoveryRooms';
import SearchLocationInput from './SearchLocationInput';
import { useDispatch } from 'react-redux';

export const SideSearch = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const formik = useFormik({
        initialValues: {
            checkInDate: new Date(searchParams.get('checkInDate')) || new Date(),
            checkOutDate: new Date(searchParams.get('checkOutDate')) || new Date(),
            guestType:searchParams.get('guestType'),
            placeType:searchParams.get('placeType'),
            location:searchParams.get('location')
        },
        onSubmit: async (values) => {  
            let term = location;
            if (location.match(/,/gi)) {
                term = term.split(',')[0];
            }
            
            setAvailableRooms(await getAvailablesRoom( {...values, location:term}, dispatch ));

            setFormToStore();
        }
    });

    const dispatch = useDispatch();

    const [guests, setGuests] = useState([]);

    const [recoverytypes, setRecoverytypes] = useState([]);

    const [location, setLocation] = useState({});

    const [availableRooms, setAvailableRooms] = useState([]);

    useEffect(async () => {
        
        setGuests(await getGuestTypesCatalog());
        setRecoverytypes(await getRecoveryTypes());
        setLocation(searchParams.get('location'));
        
        setFormToStore();
        
        setAvailableRooms(await getAvailablesRoom( formik.values, dispatch ));

    }, []);    
     
    const setFormToStore = () => {
        
        const action = {
            type:'[Bookings] Selected form checking',
            payload:{
                ...formik.values
            }
        }

        dispatch( action )
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item sm={2} marginTop={2}>
                        <SearchLocationInput setLocation={setLocation} location={location} />
                    </Grid>

                    <Grid item sm={2}>
                        <FormControl>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    onChange={(value) => formik.setFieldValue('checkInDate', value, true)}
                                    value={formik.values.checkInDate}
                                    label="check in date"
                                    renderInput={(params) => <TextField margin="normal" name="checkInDate" fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>

                    <Grid item sm={2}>
                        <FormControl>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    onChange={(value) => formik.setFieldValue('checkOutDate', value, true)}
                                    value={formik.values.checkOutDate}
                                    label="check out date"
                                    renderInput={(params) => <TextField margin="normal" name="checkOutDate" fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>

                    <Grid item sm={2}>
                        <FormControl style={{ marginTop: '1rem' }} fullWidth>
                            <InputLabel id="demo-simple-select-label">Guests</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formik.values.guestType}
                                label="guestType"
                                name="guestType"
                                onChange={formik.handleChange}
                            >
                                {guests &&
                                    guests.map((element) => (
                                        <MenuItem key={element.type} value={element.type}>
                                            {element.type}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item sm={2}>
                        <FormControl style={{ marginTop: '1rem' }}>
                            <InputLabel id="demo-simple-select-label">Recovery types</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formik.values.placeType}
                                label="placeType"
                                name="placeType"
                                defaultValue={''}
                                onChange={formik.handleChange}
                            >
                                {recoverytypes &&
                                    recoverytypes.map((element) => (
                                        <MenuItem key={element.name} value={element.name}>
                                            {element.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item sm={2}>
                        <Button
                            size="large"
                            color="primary"
                            fullWidth
                            style={{ marginTop: 15, marginRight: 10, width:'100%', height:'70%' }}
                            variant="contained"
                            type="button"
                            onClick={() => formik.submitForm()}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}
