
import firebase from '../config/fbConfig';
import moment from 'moment';

const firestore = firebase.firestore();

export const recoveryRoomIsAvailable = async ({ listingId, roomId, checkInDate, checkOutDate }) => {
    
    try {   
        
        checkInDate = moment(checkInDate).startOf('day').toDate();
        checkOutDate = moment(checkInDate).endOf('day').toDate();
        
        const data = await firestore
            .collection('booking-pays')                    
            .where('listingId', '==', listingId)
            .where('roomId', '==', roomId)
            .where('dateReserved', '>=', checkInDate)
            .where('dateReserved', '<=', checkOutDate)
            .get()
            .then(x => {
                const res = x.docs.map((doc) => doc.data())
                return res;
            });
            
          if(data.length === 0) return true;

          return false;

    } catch (error) {
        console.log(error);
        throw ('error with firestore', error)
    }

}

export const getGuestTypesCatalog = async () => {

    const data = await firestore
        .collection('guests')
        .orderBy('type')
        .get()
        .then(x => {
            const res = x.docs.map((doc) => doc.data())
            return res;
        });

    return data || [];

}

export const getRoomByGuestTypeAndListingId = async ({ guestType = '',  listingId = ''}) => {
    const subListings = await firestore.collection('sub-listings')
        .where('listingId', '==', listingId)
        .orderBy('type')
        .get()
        .then(x => {
            const res = x.docs.map((doc) => doc.data())
            return res;
        });
        
    if (subListings) {
        const newData = Object.keys(subListings).map((key, i) => {
            return {
                uid: key,
                type: subListings[key].guests,
                name: subListings[key].name,
                generalPrice: subListings[key].generalPrice
            }
        })

        const filterData = newData
            .filter(
                (element, idx) => {
                    let reg = new RegExp(guestType, 'gi')
                    if (element.type.match(reg)) {
                        return element;
                    }
                }
            )

        return filterData;
    } else {
        return [];
    }
}

export const getListingById = async ({listingId}) => {

    const subListings = await firestore.collection('sub-listings')
        .where('listingId', '==', listingId)
        .orderBy('type')
        .get()
        .then(x => {
            const res = x.docs.map((doc) => doc.data())
            return res;
        });
    

}