import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

// third party
import { ReactReduxFirebaseProvider, isLoaded } from 'react-redux-firebase';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { createFirestoreInstance } from 'redux-firestore';

// project imports
import './i18n';
import firebase from './config/fbConfig';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';

// ==============================|| REACT DOM RENDER  ||============================== //
const rrfProps = {
    firebase,
    config: firebase,
    dispatch: store.dispatch,
    createFirestoreInstance,
    userProfile: 'users', // where profiles are stored in database
    presence: 'presence', // where list of online users is stored in database
    sessions: 'sessions'
};

function AuthIsLoaded({ children }) {
    const auth = useSelector((state) => state.firebase.auth);
    if (!isLoaded(auth)) return <div style={{ height: '100vh' }}>{/* <LoadingTemplate /> */}</div>;
    return children;
}
ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <AuthIsLoaded>
                <BrowserRouter>
                    <Suspense fallback={<div>Loading... </div>}>
                        <App />
                    </Suspense>
                </BrowserRouter>
            </AuthIsLoaded>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
);
