import { Card, CardContent, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

export const TabRooms = ({ subListing ={} }) => {
    
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return subListing ? (
        <div>
            <Box style={{ marginTop: 8 }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={false}
                        aria-label="scrollable prevent tabs example"
                    >
                        {
                            Object.keys(subListing).map((key, i) => (
                                    <Tab label={subListing[key].name} key={subListing[key].name} {...a11yProps(i)}/>                                        
                            ))
                        }
                    </Tabs>
            </Box>
            {
                Object.keys(subListing).map((key, i) => (
                    <TabPanel value={value} index={i}>
                        {
                            subListing[key].images.map(element => (
                                <img
                                    style={{ margin: 2 }}
                                    src={element}
                                    width='100px'
                                >
                                </img>
                            ))
                        }
                    </TabPanel>
                ))
            }          
            
        </div>
    ) : <></>
}

  {/* <>
                                            {
                                                Object.keys(subListing).map((key, i) => (
                                                    <>
                                                        <Typography>
                                                            {
                                                                subListing[key].name
                                                            }
                                                        </Typography>
                                                        {
                                                            subListing[key].images.map(element => (
                                                                <img
                                                                    style={{ margin:2 }}
                                                                    src={element}
                                                                    width='100px'
                                                                    >
                                                                </img>
                                                            ))
                                                        }
                                                    </>
                                                ))
                                            }
                                        </> */}

{/* <Typography>
                                            {
                                                subListing[key].name
                                            }
                                        </Typography>
                                        {
                                            subListing[key].images.map(element => (
                                                <img
                                                    style={{ margin: 2 }}
                                                    src={element}
                                                    width='100px'
                                                    key={element}
                                                >
                                                </img>
                                            ))
                                        }

                                    </Tab> */}