import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useAuthInformation = () => {

    const { userData } =  useSelector( state => state.firestore.data);
    const { auth } = useSelector( state => state.firebase);

    useEffect(() => {
    }, [userData, auth]);

    return {
        auth,
        userData
    }

};
