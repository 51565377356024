import { BookingPay } from "models/bookingpay.model";
import firebase from '../config/fbConfig';

export const useBookingPay = () => {
    const firestore = firebase.firestore();

    const setBookingPay = async (bookingpay = new BookingPay()) => {
        
        const id = await firestore
            .collection('booking-pays')
            .add(bookingpay)
            .then((docRef) => {
                return docRef.id;
            });

        if (id === '') {
            throw new Error('booking pay id not generated');
        }

        return id;
    };

    return {
        setBookingPay
    };
};
