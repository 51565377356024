export const data_categories = [
    [
        'recovery_house',
        'Recovery House',
        'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Flanding%2FRecovery%20House.png?alt=media&token=27d45d67-9374-427d-ac2c-3a4c473aaf75'
    ],
    [
        'medical_clinic',
        'Medical Clinic',
        'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Flanding%2FMedical%20CLinic.png?alt=media&token=f88bccbf-acec-441b-8a9c-e1597270faac'
    ],
    [
        'hospital',
        'Hospital',
        'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Flanding%2FHospital.png?alt=media&token=68f2299f-f247-4404-a04b-3a8db46108fd'
    ],
    [
        'private_house',
        'Private House',
        'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Flanding%2FPrivate%20House.png?alt=media&token=651b1bff-e11c-4b2d-98ff-1b52ce97259f'
    ],
    [
        'hotel',
        'Hotel',
        'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Flanding%2FHotel.png?alt=media&token=aa9af3ef-f06a-46eb-ace0-a8a1148ac3e9'
    ] 
]

import recovery from '../../../assets/icons/recovery-house-icon.png';
import medicalClinic from '../../../assets/icons/medical-clinic-icon.png';
import hospital from '../../../assets/icons/hospital-icon.png';
import privateHouse from '../../../assets/icons/private-house-icon.png';
import hotel from '../../../assets/icons/hotel-icon.png';


export const data_categories2 = [
    {
        name:'recovery_house',
        title:'Recovery House',
        url:'https://firebasestorage.gooleapis.com/v0/b/recovery-house.appspot.com/o/general%2Flanding%2FRecovery%20House.png?alt=media&token=27d45d67-9374-427d-ac2c-3a4c473aaf75',
        urlIcon:recovery
    },
    {
        name:'medical_clinic',
        title:'Medical Clinic',
        url:'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Flanding%2FMedical%20CLinic.png?alt=media&token=f88bccbf-acec-441b-8a9c-e1597270faac',
        urlIcon:medicalClinic
    },
    {
        name:'hospital',
        title:'Hospital',
        url:'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Flanding%2FHospital.png?alt=media&token=68f2299f-f247-4404-a04b-3a8db46108fd',
        urlIcon:hospital
    },
    {
        name:'private_house',
        title:'Private House',
        url:'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Flanding%2FPrivate%20House.png?alt=media&token=651b1bff-e11c-4b2d-98ff-1b52ce97259f',
        urlIcon:privateHouse
    },
    {
        name:'hotel',
        title:'Hotel',
        url:'https://firebasestorage.googleapis.com/v0/b/recovery-house.appspot.com/o/general%2Flanding%2FHotel.png?alt=media&token=aa9af3ef-f06a-46eb-ace0-a8a1148ac3e9',
        urlIcon:hotel
    }
];

import like from '../../../assets/icons/like-icon.png';
import search from '../../../assets/icons/search-icon.png';
import login from '../../../assets/icons/user-icon.png';

export const menuFooter = [
    {
        name:'login',
        title:'Login',
        url:'/login',
        urlIcon:login
    },
    {
        name:'search',
        title:'Search',
        url:'/search',
        urlIcon:search
    },
    {
        name:'like',
        title:'Like',
        url:'/like',
        urlIcon:like
    }
];

