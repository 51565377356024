import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

export const useFirebase = (collection = '') => {

    useFirestoreConnect([{ collection, storeAs: 'data' }]);

    const data = useSelector((state) => state.firestore.data.data);

    const [fbData, setFbData] = useState([]);

    const getFirebase = () => {
        if (data) {
            const newData = Object.keys(data).map((key, i) => {
                return {
                    uid: key,
                    type: data[key].type
                }
            })
            setFbData(newData);
        }
    };

    useEffect(() => {
        getFirebase();
    }, [data])

    return {
        fbData
    };

};
