import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography, useFormControl } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router'

export const BookingSearchBar = () => {

    const [value, setValue] = React.useState(new Date());
    const navigate = useNavigate();

    const handleBooking = () => {
        debugger
        navigate('/bookings/booking-check');
    };

    return (
        <div>
            <Typography variant='h1' color='white'>
                Recovery house
            </Typography>
            <Typography variant='h3' color='white' marginTop={2}>
                Ingresá tus fechas y elegí entre 32.732 estancias y otras opciones para tu recuperacion.
            </Typography>
            <Box
                sx={{
                  display:'flex',
                  flexDirection:'row',
                  marginTop:2
                }}
            >
                <FormControl sx={{ width: '25ch', marginRight:1 }}>
                    <OutlinedInput placeholder="Please enter text" />
                    {/* <MyFormHelperText /> */}
                </FormControl>
                <FormControl sx={{ width: '25ch', marginRight:1 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}                            
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: '25ch', marginRight:1 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}                            
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                        />
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ width: '25ch', marginRight:1 }}>
                    <InputLabel id="type-select-label">Type room</InputLabel>
                    <Select labelId="type-select-label" id="type-select">
                        <MenuItem >
                            General
                        </MenuItem>
                        <MenuItem >
                            Deluxe
                        </MenuItem>
                        <MenuItem >
                            Premier
                        </MenuItem>
                    </Select>
                </FormControl>
                <Button 
                    onClick={handleBooking}                    
                    variant="outlined" >
                    Search
                </Button>
            </Box>
        </div>
    )
}
