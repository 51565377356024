import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { arrayUnion } from 'firebase/firestore';
import { get, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

import Swal from 'sweetalert2';
 import moment from 'moment';


function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
}

export const signIn =
    (credentials) =>
    (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .auth()
            .signInWithEmailAndPassword(credentials.email, credentials.password)
            .then(() => {
                dispatch({ type: 'LOGIN_SUCCESS' });
                return { type: 'LOGIN_SUCCESS' };
            })
            .catch((err) => {
                dispatch({ type: 'LOGIN_ERROR', err });
                return { type: 'LOGIN_ERROR', err };
            });
    };

export const signOut =
    () =>
    (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .auth()
            .signOut()
            .then(() => {
                dispatch({ type: 'SIGNOUT_SUCCESS' });
            });
    };

export const addData =
    (userData) =>
    (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();
        const firebase = getFirebase();
        const path = `users/${firebase.auth().currentUser.uid}`;
        const user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(user.email, userData.currentPassword);

        user.reauthenticateWithCredential(credential)
            .then(() => {
                user.updatePassword(userData.newPassword)
                    .then(() => {
                        firestore.doc(path).set({
                            fname: userData.firstName,
                            lname: userData.lastName,
                            defaultPassword: false
                        });
                        dispatch({ type: 'SAVE_DATA_SUCCESS' });
                    })
                    .catch((err) => {
                        dispatch({ type: 'SAVE_DATA_ERROR' }, err);
                    });
            })
            .catch((err) => {
                dispatch({ type: 'SAVE_DATA_ERROR' }, err);
            });
    };

export const signUp =
     (newUser) =>
     async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        const { isAdmin } = newUser;
        const data = await firestore
            .collection('roles')
            .where('status','==', true)
            .get()
            .then((x) => {
                const res = x.docs.map((doc) => doc.data());
                return res;
            });
        
        let roles = [];

        data.forEach(role => {
            if( isAdmin && role.name === 'ADMIN' ){
                roles.push( role.name );
                return;
            }
            if( role.name === 'GENERAL'){
                roles.push( role.name );
            }

        });

        firebase
            .auth()
            .createUserWithEmailAndPassword(newUser.email, newUser.password)
            .then((resp) => {
                console.log(resp);
                firestore
                    .collection('users')
                    .doc(resp.user.uid)
                    .set({
                        firstName: newUser.fname,
                        lastName: newUser.lname,
                        initials: newUser.fname[0] + newUser.lname[0],
                        roles
                    });
            })
            .then(() => {
                dispatch({ type: 'SIGNUP_SUCCESS' });
            })
            .catch((err) => {
                dispatch({ type: 'SIGNUP_ERROR', err });
            });
    };

export const deleteListing =
    (docId, creator) =>
    (dispatch, getState, { getFirestore, getFirebase }) => {
        console.log('submiting', docId);

        // make async call to database
        const firestore = getFirestore();
        const firebase = getFirebase();
        const userId = firebase.auth().currentUser.uid;

        if (userId !== creator) {
            console.log('ERROR');
            dispatch({ type: 'DELETE_LISTING_ERROR' });
        }

        firestore
            .collection('listing_info')
            .doc(docId)
            .delete()
            .then(() => {
                dispatch({ type: 'DELETE_LISTING_SUCCESS', docId });
            })
            .catch((err) => {
                console.log('ERROR', err);
                dispatch({ type: 'DELETE_LISTING_ERROR' }, err);
            });
    };

export const saveListing =
    (listingInfo, images, documents) =>
    (dispatch, getState, { getFirestore, getFirebase }) => {
        console.log('submiting', listingInfo, images, documents);

        // make async call to database
        const firestore = getFirestore();
        const firebase = getFirebase();
        const userId = firebase.auth().currentUser.uid;
        let docId = null;

        let listingData = listingInfo;
        listingData = {
            ...listingInfo.location_info,
            ...listingData,
            images: [],
            terms_conditions: [],
            creator: userId,
            posted: new Date()
        };
        delete listingData.location_info;

        firestore
            .collection('listing_info')
            .add(listingData)
            .then((docRef) => {
                docRef.addCollection('sub-listings');
                docId = docRef.id;
                const storage = getStorage();
                if (images) {
                    images.forEach((image) => {
                        const storageRef = ref(storage, `public/listings/${docRef.id}/images/${image.name}`);

                        // Create the file metadata
                        /** @type {any} */
                        const metadata = {
                            contentType: 'image/jpeg'
                        };

                        // Upload file and metadata to the object 'images/mountains.jpg'
                        const uploadTask = uploadBytesResumable(storageRef, image, metadata);

                        // Listen for state changes, errors, and completion of the upload.
                        uploadTask.on(
                            firebase.storage.TaskEvent.STATE_CHANGED,
                            (snapshot) => {
                                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                console.log(`Upload is ${progress}% done`);
                            },
                            (error) => {
                                console.log(error.code);
                            },
                            () => {
                                // Upload completed successfully, now we can get the download URL
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    firestore
                                        .collection('listing_info')
                                        .doc(docRef.id)
                                        .update({ images: arrayUnion(downloadURL) });
                                });
                            }
                        );
                    });
                }
                if (documents) {
                    documents.forEach((documents) => {
                        const storageRef = ref(storage, `public/listings/${docRef.id}/documents/${documents.name}`);

                        // Upload file and metadata to the object 'images/mountains.jpg'
                        const uploadTask = uploadBytesResumable(storageRef, documents);

                        // Listen for state changes, errors, and completion of the upload.
                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                console.log(`Upload is ${progress}% done`);
                            },
                            (error) => {
                                console.log(error.code);
                            },
                            () => {
                                // Upload completed successfully, now we can get the download URL
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    firestore
                                        .collection('listing_info')
                                        .doc(docRef.id)
                                        .update({ terms_conditions: arrayUnion(downloadURL) });
                                });
                            }
                        );
                    });
                }
            })
            .then(() => {
                dispatch({ type: 'SAVE_LISTING_SUCCESS', docId });
            })
            .catch((err) => {
                console.log('ERROR', err);
                dispatch({ type: 'SAVE_LISTING_ERROR' }, err);
            });
    };

export const editListing =
    (listingId, listingInfo, images, documents) =>
    (dispatch, getState, { getFirestore, getFirebase }) => {
        console.log('editing', listingInfo, images, documents);

        // make async call to database
        const firestore = getFirestore();
        const firebase = getFirebase();
        const userId = firebase.auth().currentUser.uid;

        let listingData = listingInfo;
        listingData = {
            ...listingData,
            // images: listingInfo.images,
            // terms_conditions: listingInfo.documents,
            creator: userId,
            posted: new Date()
        };
        
        firestore
            .collection('listing_info')
            .doc(listingId)
            .update(listingData)
            .then(() => {
                const storage = getStorage();
                if (images) {
                    images.forEach((image) => {
                        console.log(image);
                        if (!isValidHttpUrl(image)) {
                            const storageRef = ref(storage, `public/listings/${listingId}/images/${image.name}`);

                            // Create the file metadata
                            /** @type {any} */
                            const metadata = {
                                contentType: 'image/jpeg'
                            };

                            // Upload file and metadata to the object 'images/mountains.jpg'
                            const uploadTask = uploadBytesResumable(storageRef, image, metadata);

                            // Listen for state changes, errors, and completion of the upload.
                            uploadTask.on(
                                firebase.storage.TaskEvent.STATE_CHANGED,
                                (snapshot) => {
                                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                    console.log(`Upload is ${progress}% done`);
                                },
                                (error) => {
                                    console.log(error.code);
                                },
                                () => {
                                    // Upload completed successfully, now we can get the download URL
                                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                        firestore
                                            .collection('listing_info')
                                            .doc(listingId)
                                            .update({ images: arrayUnion(downloadURL) });
                                    });
                                }
                            );
                        }
                    });
                }
                if (documents) {
                    documents.forEach((documents) => {
                        const storageRef = ref(storage, `public/listings/${listingId}/documents/${documents.name}`);

                        // Upload file and metadata to the object 'images/mountains.jpg'
                        const uploadTask = uploadBytesResumable(storageRef, documents);

                        // Listen for state changes, errors, and completion of the upload.
                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                console.log(`Upload is ${progress}% done`);
                            },
                            (error) => {
                                console.log(error.code);
                            },
                            () => {
                                // Upload completed successfully, now we can get the download URL
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    firestore
                                        .collection('listing_info')
                                        .doc(listingId)
                                        .update({ terms_conditions: arrayUnion(downloadURL) });
                                });
                            }
                        );
                    });
                }
            })
            .then(() => {
                dispatch({ type: 'EDIT_LISTING_SUCCESS' });
            })
            .catch((err) => {
                console.log('ERROR', err);
                dispatch({ type: 'EDIT_LISTING_ERROR' }, err);
            });
    };

export const editListingPhotos = 
    (listingId, images) => 
    (dispatch, getState, { getFirestore, getFirebase }) => {
        // make async call to database
        const firestore = getFirestore();
        const firebase = getFirebase();
        const userId = firebase.auth().currentUser.uid;

        const listingData = {
            images,
            creator: userId,
            posted: new Date()
        };

        firestore
            .collection('listing_info')
            .doc(listingId)
            .update(listingData)
            .then(() => {
                
            })
        
    }

export const saveSubListing =  
    (subListing, images) => 
    async (dispatch, getState, { getFirestore, getFirebase }) => {

    const { id, listingId } = subListing;
    const firestore = getFirestore();
    
    const recoveryObj = await firestore
        .collection('listing_info')
        .doc(listingId)
        .get()
        .then((snapshot) => {
            console.log(snapshot.data());
            return snapshot.data();
        });
    
    const { type_place, address } = recoveryObj;
    
    subListing = { ...subListing, type_place, address };

    if(id !== ""){
        editSubListing(subListing, images,getFirestore,getFirebase);
    }else{
        setSubListing(subListing, images, getFirestore, getFirebase);
    }

}

const setSubListing = (subListing, images,getFirestore,getFirebase) => {
    const { id, listingId, totalRooms } = subListing;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const createdBy = firebase.auth().currentUser.uid;
    const createdDate = new Date().toLocaleString();
    subListing = { ...subListing, createdBy, createdDate, images:[] };
    delete subListing.id;
  
    firestore.collection('sub-listings')
             .add(subListing)
             .then((docRef) => {
                 UploadImages({ docRef, images, listingId, id, firebase, firestore, subListing });
             });
    
        
}

const editSubListing = (subListing, images,getFirestore,getFirebase) => {
    const { id, listingId } = subListing;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const updatedBy = firebase.auth().currentUser.uid;
    const updatedDate = new Date().toLocaleString();
    subListing = { ...subListing, updatedBy, updatedDate, images:[] };
    delete subListing.id;
    delete subListing.totalRooms;
    firestore.collection('sub-listings')
        .doc(id)
        .update(subListing)
        .then((docRef) => {
            UploadImages({ docRef, images, listingId, id, firebase, firestore, subListing });
        })

}

const UploadImages = ({docRef, images, listingId, id, firebase, firestore, subListing}) => {
                let docId = docRef?.id ? docRef.id : id;
                const storage = getStorage();

                if (images) {
                    Object.keys(images).map(key => {
                        images[key].forEach((image) => {

                            let date = Date.now();

                            const storageRef = ref(storage, `public/sub-listings/${docId}/images/${date}-${image.name}`);
    
                            /** @type {any} */
                            const metadata = {
                                contentType: 'image/jpeg'
                            };
    
                            const uploadTask = uploadBytesResumable(storageRef, image, metadata);
                            
                            uploadTask.on(
                                firebase.storage.TaskEvent.STATE_CHANGED,
                                (snapshot) => {
                                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                },
                                (error) => {
                                    console.log(error.code);
                                },
                                () => {
                                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                                            firestore
                                                .collection('sub-listings')
                                                .doc(docId)
                                                .update({ images: arrayUnion(downloadURL) })
                                                .then(() => {
                                                    window.location.reload(false);
                                                });

                                        });
                                }
                            );
                        });
                    });

                    if(docRef?.id){
                        window.location.reload(true);
                    }
                }
            }

export const editSubListingImg = 
    (subListingId, images) =>
        (dispatch, getState, { getFirestore, getFirebase }) => {
            // make async call to database
            const firestore = getFirestore();
            const firebase = getFirebase();
            const userId = firebase.auth().currentUser.uid;

            const listingData = {
                images,
                updatedBy: userId,
                updatedDate: new Date()
            };

            firestore
                .collection('sub-listings')
                .doc(subListingId)
                .update(listingData)
                .then(() => {

                })
    
}

export const deleteSublisting =
    (docId, createdBy) =>
        (dispatch, getState, { getFirestore, getFirebase }) => {

        // make async call to database
        const firestore = getFirestore();
        const firebase = getFirebase();
        const userId = firebase.auth().currentUser.uid;

        // if (userId !== createdBy) {
        //     console.log('ERROR');
        //     dispatch({ type: 'DELETE_LISTING_ERROR' });
        // }

        firestore
            .collection('sub-listings')
            .doc(docId)
            .delete()
            .then(() => {
                dispatch({ type: 'DELETE_LISTING_SUCCESS', docId });
            })
            .catch((err) => {
                console.log('ERROR', err);
                dispatch({ type: 'DELETE_LISTING_ERROR' }, err);
            });

        }

export const getSublistingsByListingIdTypeRoom = ({ }) =>
    async (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();
        const firebase = getFirebase();

        const data = await firestore
                    .collection('checking-rooms')                    
                    .where('listingId', '==', listingId)
                    .where('date', '>=', checkInDate)
                    .where('date', '<=', checkOutDate)
                    .get()
                    .then(x => {
                        const res = x.docs.map((doc) => doc.data())
                        return res;
                    });
    }

export const checkInAvailableRooms =
    ({ listingId, checkInDate, checkOutDate, room }) =>
        async (dispatch, getState, { getFirestore, getFirebase }) => {
            
            checkInDate = moment(checkInDate).startOf('day').toDate();
            checkOutDate = moment(checkOutDate).endOf('day').toDate();
                        
            const firestore = getFirestore();
            const firebase = getFirebase();
            const createdBy = firebase.auth().currentUser.uid;
            const createdDate = new Date();
            const { uid, name, type, generalPrice } = room;
            
            try {   
               
                const data = await firestore
                    .collection('booking-days')                    
                    .where('listingId', '==', listingId)
                    .where('roomId', '==', uid)
                    .where('dateReserved', '>=', checkInDate)
                    .where('dateReserved', '<=', checkOutDate)
                    .get()
                    .then(x => {
                        const res = x.docs.map((doc) => doc.data())
                        return res;
                    });
                    
                    let bookingDays = [];
                    let totalCashDays = 0;
                if (data.length === 0) {
                    
                    const days = getDaysByTwoDates(checkInDate, checkOutDate);
                    
                    days.forEach((element) => {
                        totalCashDays =  generalPrice + totalCashDays;

                        bookingDays = [
                            ...bookingDays,
                            {
                                date: element,
                                listingId,
                                roomId: uid,
                                createdBy,
                                createdDate,
                                room
                            }
                        ];
                    });
                    const action = {
                        type:'[Bookings] Reserve Booking',
                        payload:{
                            bookingDays,
                            booking:{
                                listingId, checkInDate, checkOutDate, ...room,
                                totalCashDays
                            }
                        }
                    }

                    dispatch(action);
                    
                    return;

                }

                Swal.fire({
                    title: 'Room not available for those dates',
                    text: 'Try with other dates please',
                    icon: 'info',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    dispatch({
                        type:'[Bookings] Reserve Booking',
                        payload:[]
                    });
                });
            

            } catch (error) {
                console.log(error);
            }

        }

export const setCheckingDates =
    ({ id, create_time, status, payer, name, address}) =>
        async (dispatch, getState, { getFirestore, getFirebase }) => {
            const firestore = getFirestore();
            const firebase = getFirebase();
            const createdDate = new Date();
            const createdBy = firebase.auth().currentUser.uid;

            try {

                const { bookings:{selectedRoom:{ booking, bookingDays } }} = getState( state => state);
                
                
                if( booking && bookingDays){
                    const { type, generalPrice, totalCashDays } = booking;
                    if(bookingDays.length > 0){
                        
                            const { checkInDate, checkOutDate, listingId, roomId } = booking;
                            firestore
                                .collection('booking-pays')
                                .add({
                                    id,
                                    create_time,
                                    status,
                                    ...payer,
                                    checkInDate,
                                    checkOutDate,
                                    createdDate,
                                    createdBy,
                                    listingId,
                                    roomId,
                                    generalPrice,
                                    totalCashDays,
                                    days:bookingDays.length
                                })
                                .then((docRef) => {
                                    const bookingPayId = docRef.id;
                                        bookingDays.forEach(({ date: dateReserved, listingId, roomId }) => {
                                            firestore
                                                .collection('booking-days')
                                                .add({
                                                    bookingPayId,
                                                    status: 'RESERVED',
                                                    createdDate,
                                                    dateReserved,
                                                    listingId,
                                                    roomId,
                                                    createdDate,
                                                    createdBy,
                                                    generalPrice
                                                })
                                                .then(() => {
                                                    Swal.fire({
                                                        title: 'Ok',
                                                        text: 'Your room was booked succesfull',
                                                        icon: 'info',
                                                        confirmButtonText: 'Ok'
                                                    }).then(() => {
                                                        window.location.pathname = '/';
                                                    });
                                                });
                                        });
                                    
                                });

                    }

                }else{
                    throw Error('bookings no available')
                }

               
            } catch (error) {
                console.log(error);
            }

        }


export const getGuestsTypes = () =>
    async (dispatch, getState, { getFirestore, getFirebase }) => {
        const firestore = getFirestore();        

        const data = await firestore
            .collection('guests')
            .orderBy('type')
            .get()
            .then(x => {
                const res = x.docs.map((doc) => doc.data())
                return res;
            });

        dispatch({
            type:'[Guests] Get Guests',
            payload: data
        });
    }

export const getDaysByTwoDates = (startDate, endDate) => {
    
    var dates = [];

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while( currDate <= lastDate ) {        
        dates.push(currDate.clone().toDate());
        currDate = moment(currDate).add(1, 'days');

    }

    if(dates.length === 0){
        dates.push(startDate);
        return dates;
    }
    return dates;
};

const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

const recoveryRoomIsAvailable = async ({ getFirestore, listingId, uid:roomId, checkInDate, checkOutDate }) => {

    const firestore = getFirestore();

    try {   
        const data = await firestore
            .collection('booking-days')                    
            .where('listingId', '==', listingId)
            .where('roomId', '==', roomId)
            .where('dateReserved', '>=', checkInDate)
            .where('dateReserved', '<=', checkOutDate)
            .get()
            .then(x => {
                const res = x.docs.map((doc) => doc.data())
                return res;
            });
            
          if(data) return true;

          return false;

    } catch (error) {
        console.log(error);
        throw new Exception('error with firestore', error)
    }

}